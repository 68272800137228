import { errorHelper } from 'helpers';
import { getAuthInstance } from 'utils/axiosInstance';

const backendURL = process.env.REACT_APP_BACKEND_URL;
const instanceAuth = getAuthInstance(`${backendURL}/tags`);

export async function getTags(): Promise<string[]> {
  try {
    const { data } = await instanceAuth.get('/');
    return data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function createTags(tagNames: string[]): Promise<void> {
  try {
    if (tagNames.length === 0) return;
    await instanceAuth.post('/', { tagNames });
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}
