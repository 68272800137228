/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react';
import 'styles/components/apps/appPlatforms.scss';
import { appInterface } from 'interfaces';
import { errorHelper } from 'helpers';
import { InputField, Modal, SelectorField } from 'components/generic';
import { platformOptions } from 'utils/constants';
import { useSnackbar } from 'notistack';
import ExternalAppSearch from './externalAppSearch';
import { PlatformSelector } from 'interfaces/appInterface';
import { Button } from 'components/generic/Button';

interface NewPlatformPopupProps {
  platforms?: any;
  hideModal: Function;
  appName?: string;
  addNewPlatform?: (platform: appInterface.INewPlatform) => void;
  preSelectedPlatform?: PlatformSelector;
  onAdd?: (
    platform?: appInterface.INewPlatform,
    app?: appInterface.IExternalParsedApp,
    country?: { value: string; label: string }
  ) => void;
}

function NewPlatformPopup({
  platforms,
  hideModal,
  appName,
  addNewPlatform,
  preSelectedPlatform,
  onAdd,
}: NewPlatformPopupProps) {
  let platformKeys: string[] = [];
  if (platforms) platformKeys = Object.keys(platforms);
  const [platformData, setPlatformData] = useState<appInterface.IPlatform>({});
  const [selectedPlatform, setSelectedPlatform] = useState<PlatformSelector | undefined>(preSelectedPlatform);
  const [filteredPlatforms, setFilteredPlatforms] = useState<{ value: string; label: string }[]>(platformOptions);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setPlatformData({
      ...platformData,
      [name]: value,
    });
  };

  function reset() {
    setSelectedPlatform(undefined);
    setPlatformData({});
    setFilteredPlatforms(platformOptions);
    hideModal();
  }

  async function submitPlatform(
    { newPlatformType, newPlatformData }: appInterface.INewPlatform,
    newAppData?: appInterface.IExternalParsedApp,
    country?: { value: string; label: string }
  ) {
    try {
      if (!selectedPlatform) throw new errorHelper.CodeError('Please select a platform', 400);
      if (newPlatformType !== appInterface.Platform.android && newPlatformType !== appInterface.Platform.itunes) {
        if (!platformData.url || !platformData.price) {
          throw new errorHelper.CodeError('You need to fill in all fields', 400);
        }
      }
      reset();
      if (addNewPlatform) await addNewPlatform({ newPlatformType, newPlatformData });
      if (newAppData) {
        onAdd?.(
          undefined,
          {
            ...newAppData,
            platforms: {
              [newPlatformType]: newAppData.platforms[newPlatformType],
            },
          },
          country
        );
      } else {
        onAdd?.({ newPlatformType, newPlatformData });
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  useEffect(() => {
    if (platformKeys.length > 0) {
      const filtering = platformOptions.filter(
        (option) => !platformKeys.some((key) => key === option.value) || !platforms[option.value].url
      );
      setFilteredPlatforms(filtering);
    }
  }, []);

  useEffect(() => {
    setPlatformData({
      ...platformData,
      type: selectedPlatform?.value as appInterface.Platform,
    });
  }, [selectedPlatform]);

  return (
    <Modal hideModal={() => reset()}>
      <div className="newPlatformPopup">
        <h3>Submit additional platform</h3>
        <SelectorField
          name="platforms"
          label="Platforms"
          options={filteredPlatforms}
          value={selectedPlatform}
          handleChange={(changedPlatform: { value: appInterface.Platform; label: string }) =>
            setSelectedPlatform(changedPlatform)
          }
        />
        {!!selectedPlatform &&
          (selectedPlatform?.value === appInterface.Platform.android ||
          selectedPlatform?.value === appInterface.Platform.itunes ? (
            <ExternalAppSearch
              addNewPlatform={({
                newPlatformData,
                country,
              }: {
                newPlatformData: appInterface.IExternalParsedApp;
                country?: { value: string; label: string };
              }) =>
                submitPlatform(
                  {
                    newPlatformType: selectedPlatform.value,
                    newPlatformData: newPlatformData.platforms[selectedPlatform.value]!,
                  },
                  newPlatformData,
                  country
                )
              }
              platform={selectedPlatform?.value}
              appName={appName}
            />
          ) : (
            <div className="addPlatform">
              <InputField name="url" label="App URL*" value={platformData.url} handleChange={(e) => handleChange(e)} />
              <InputField
                type="number"
                name="price"
                label="Price*"
                value={platformData.price}
                handleChange={(e) => handleChange(e)}
              />
              <div className="splitInput">
                <InputField
                  type="number"
                  name="rating"
                  label="Rating"
                  value={platformData.rating}
                  handleChange={(e) => handleChange(e)}
                />
                <InputField
                  name="ratingCount"
                  label="Rating Count"
                  value={platformData.ratingCount}
                  handleChange={(e) => handleChange(e)}
                />
              </div>
              <Button
                type="primary"
                onClick={() =>
                  submitPlatform({
                    newPlatformType: selectedPlatform.value,
                    newPlatformData: platformData,
                  })
                }
                className="submitButton"
              >
                SUBMIT
              </Button>
            </div>
          ))}
      </div>
    </Modal>
  );
}

export default NewPlatformPopup;
