import { appInterface, appSuggestionInterface, pageInterface } from 'interfaces';
import { errorHelper } from 'helpers';
import { getAuthInstance, getInstance, getSoftAuthInstance } from 'utils/axiosInstance';
import { ISubscription } from 'interfaces/userInterface';

const backendURL = process.env.REACT_APP_BACKEND_URL;
const appInstance = getInstance(`${backendURL}/apps`);
const appInstanceAuth = getAuthInstance(`${backendURL}/apps`);
const appInstanceSoftAuth = getSoftAuthInstance(`${backendURL}/apps`);
const playStoreInstanceAuth = getAuthInstance(`${backendURL}/play-store`);
const itunesInstanceAuth = getAuthInstance(`${backendURL}/itunes`);

export async function createApp(appData: appInterface.INewApp) {
  try {
    await appInstanceAuth.post('/', JSON.stringify({ appData }));
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function reportApp({ uuid, message }: { uuid: string; message: string }) {
  try {
    await appInstanceAuth.post('/report', JSON.stringify({ uuid, message }));
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function getAppById(uuid: string): Promise<{
  app: appInterface.IApp;
  subscriptions: ISubscription[];
  total: { totalInPreferred: number; currency: string };
}> {
  try {
    const app = await appInstanceSoftAuth.get(`/id/${uuid}`);
    return app.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function getManyAppsById(uuidList: string[], limit?: number) {
  try {
    const apps = await appInstance.get('/search/id', {
      params: { uuidList, limit },
    });
    return apps.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function getManyAppsByName(name: string) {
  try {
    const apps = await appInstance.get('/search/name', { params: { name } });
    return apps.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function getMergedAppsByName(name: string) {
  try {
    const apps = await appInstance.get('/search/name/merged', {
      params: { name },
    });
    return apps.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function getManyAppsByCategories(
  categoryList: string[],
  page: number
): Promise<pageInterface.IPage<appInterface.IApp>> {
  try {
    const apps = await appInstance.post(
      '/search/categories',
      { categoryList },
      {
        params: { page },
      }
    );
    return apps.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function getManyAppsByTags(
  tagList: string[],
  page: number
): Promise<pageInterface.IPage<appInterface.IApp>> {
  try {
    const apps = await appInstance.post(
      '/search/tags',
      { tagList },
      {
        params: { page },
      }
    );
    return apps.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function getApps() {
  try {
    const apps = await appInstance.get('/');
    return apps.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function deleteApp(uuid: string) {
  try {
    await appInstance.delete('/', { data: JSON.stringify({ uuid }) });
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function searchiTunesByName({
  name,
  country,
}: {
  name: string;
  country?: string;
}): Promise<appInterface.IExternalParsedApp[]> {
  try {
    const suggestions = await itunesInstanceAuth.get('/', {
      params: { name, country },
    });
    return suggestions.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function addNewPlatform({
  appId,
  newPlatform,
}: {
  appId: string;
  newPlatform: { name: string; data: appInterface.IPlatform };
}) {
  try {
    return await appInstanceAuth.post('/platform', JSON.stringify({ appId, newPlatform }));
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function addVerifiedPlatform({
  appId,
  platformData,
  platformType,
}: {
  appId: string;
  platformData: appInterface.IPlatform;
  platformType: appInterface.Platform;
}) {
  try {
    return await appInstanceAuth.post('/platform/verified', JSON.stringify({ appId, platformData, platformType }));
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function getAllApps({ page }: { page: number }) {
  try {
    const apps = await appInstance.get('/all', { params: { page } });
    return apps.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function searchPlayStoreByName({
  name,
  region,
}: {
  name: string;
  region: string;
}): Promise<appInterface.IExternalParsedApp[]> {
  try {
    const suggestions = await playStoreInstanceAuth.get('/', {
      params: { name, region },
    });
    return suggestions.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

interface searchExtermalByNameProps {
  name: string;
  country?: string;
  platform: appInterface.Platform;
}

export async function searchExtermalByName({
  name,
  country,
  platform,
}: searchExtermalByNameProps): Promise<appInterface.IExternalParsedApp[]> {
  try {
    if (platform === appInterface.Platform.itunes) {
      const suggestions = await itunesInstanceAuth.get('/', {
        params: { name, country },
      });
      return suggestions.data;
    } else if (platform === appInterface.Platform.android) {
      const suggestions = await playStoreInstanceAuth.get('/', {
        params: { name, region: country },
      });
      return suggestions.data;
    }
    return [];
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function suggestAppUpdateById(appUuid: string, appData: appSuggestionInterface.INewAppSuggestion) {
  try {
    const app = await appInstanceAuth.post(`/id/${appUuid}/suggest`, appData);
    return app.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}
