import React, { useCallback, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import 'styles/components/generic/menu.scss';
import { Suggestions } from 'components/search';
import { BsXLg, BsPerson } from 'react-icons/bs';
import { InnerMenuProps } from './menu';
import Logo from 'assets/icons/Logo';
import { Button } from './Button';

export const DesktopMenu = (props: InnerMenuProps) => {
  const { logout, isLoggedIn, getAllApps, count, lists, apps } = props;
  const { search, setSearch, showSearch, setShowSearch } = props;
  const navigate = useNavigate();

  const searchFieldRef = useRef(null);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === '/' && searchFieldRef.current !== null) {
      event.preventDefault();
      (searchFieldRef.current as HTMLInputElement).focus();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div className="menu">
      <div className="logoBox">
        <Link className="logo" to="/">
          <Logo />
        </Link>
      </div>

      {isLoggedIn && (
        <div className="menuBox">
          <Link className="menuText" to="/new-app">
            Add New App
          </Link>
          <Link className="menuText" to="/lists/owned">
            My Lists
          </Link>
          <Link className="menuText" to="/lists/followed">
            Favourite Lists
          </Link>
          <Link className="menuText" to="/subscriptions">
            My Subscriptions
          </Link>
        </div>
      )}
      <div
        className="searchBox"
        onBlur={(e) => {
          if (e.relatedTarget === null) setShowSearch(false);
        }}
        onFocus={() => setShowSearch(true)}
      >
        {['/login', '/register'].indexOf(window.location.pathname) === -1 ? (
          <div className="searchWrap">
            <div className="searchContainer">
              <input
                ref={searchFieldRef}
                className="searchField"
                value={search}
                placeholder="Search Apps and Collections"
                onChange={(v) => setSearch(v.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Escape') {
                    event.currentTarget.blur();
                  }
                }}
              />
              {search.length > 0 && <BsXLg className="clearIcon" onClick={() => setSearch('')} />}

              {search.length > 2 && (!!apps.length || !!lists.length) && showSearch && (
                <Suggestions appClicked={() => setSearch('')} lists={lists} apps={apps} />
              )}
              {search === '' && <span className="shortcutKey">/</span>}
            </div>

            {isLoggedIn ? (
              <>
                <BsPerson onClick={() => navigate('/profile')} className="widget" />
                <FiLogOut onClick={() => logout()} className="widget" />
              </>
            ) : (
              <Button onClick={() => navigate('/login')} className="loginButton">
                Login
              </Button>
            )}
          </div>
        ) : null}
        {search.length === 0 && apps.length < count && showSearch && (
          <button type="button" onClick={() => getAllApps()} className="cta">
            Load More
          </button>
        )}
      </div>
    </div>
  );
};
