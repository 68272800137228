import React, { useEffect, useState } from 'react';
import 'styles/pages/profilePage.scss';
import { BsGear } from 'react-icons/bs';
import { ButtonWidget } from 'components/generic';
import { IUser } from 'interfaces/userInterface';
import { Helmet } from 'react-helmet';

function ProfilePage({ userData }: { userData: IUser }) {
  const [user, setUser] = useState<IUser>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setUser(userData);
    setLoading(false);
  }, [userData]);

  return (
    <div className="profilePage">
      {user && !loading && (
        <>
          <Helmet>
            <title>Profile - Slocco</title>
          </Helmet>
          <h1>{user.name}</h1>
          <div className="widgets">
            <ButtonWidget
              icon={<BsGear className="icon" />}
              title="Profile Settings"
              description="Manage your profile settings"
              route="/profile/settings"
            />
          </div>
        </>
      )}
    </div>
  );
}

export default ProfilePage;
