/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import 'styles/pages/onboarding/credentials.scss';
import { InputField, PasswordInputField } from 'components/generic';
import { userController } from 'controllers';
import { userInterface } from 'interfaces';
import { Helmet } from 'react-helmet';

function JoinPage({ setIsLoggedIn }: { setIsLoggedIn: React.Dispatch<React.SetStateAction<any>> }) {
  const navigate = useNavigate();
  const [registerData, setRegisterData] = useState<userInterface.INewUser>();
  const { enqueueSnackbar } = useSnackbar();
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setRegisterData({
      ...registerData,
      [e.target.name]: value,
    });
  }

  async function handleRegister(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      if (registerData) await userController.register(registerData);
      enqueueSnackbar('Successfully joined', { variant: 'success' });
      setIsLoggedIn(true);
      return navigate('/verify-email');
    } catch (error: any) {
      return enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  return (
    <div className="registerPage">
      <Helmet>
        <title>Join the waitlist - Slocco</title>
      </Helmet>
      <h1 className="title">Join the Waitlist</h1>
      <form className="inputWrapper" onSubmit={(e) => handleRegister(e)}>
        <InputField
          name="name"
          required
          label="Name"
          value={registerData?.name}
          handleChange={(e) => handleChange(e)}
        />
        <InputField
          name="username"
          required
          label="Username"
          value={registerData?.username}
          handleChange={(e) => handleChange(e)}
        />
        <InputField
          name="email"
          type="email"
          required
          label="Email"
          value={registerData?.email}
          handleChange={(e) => handleChange(e)}
        />
        <PasswordInputField
          name="password"
          required
          label="Password"
          value={registerData?.password}
          handleChange={(e) => handleChange(e)}
        />
        <PasswordInputField
          name="confirmPassword"
          required
          label="Confirm Password"
          value={registerData?.confirmPassword}
          handleChange={(e) => handleChange(e)}
        />
        <div
          style={{
            width: '100%',
            display: 'flex',
            margin: '0.25rem 0',
            alignItems: 'center',
          }}
        >
          <input
            onChange={(e) => setTermsAgreed(e.target.checked)}
            type="checkbox"
            id="terms"
            name="terms"
            style={{ height: 18, width: 18 }}
            checked={termsAgreed}
          />
          <label htmlFor="terms">
            I agree to{' '}
            <a href="/terms-conditions" target="_blank" style={{ color: '#4d73cf' }}>
              Terms and Conditions
            </a>
            .
          </label>
        </div>
        <button type="submit" disabled={!termsAgreed} className="btnRegister">
          Join
        </button>
      </form>
      <p className="login">
        Already have an account?
        <Link className="link" to="/login">
          Login
        </Link>
      </p>
    </div>
  );
}

export default JoinPage;
