import React, { useState, useEffect } from 'react';
import 'styles/components/generic/customAttribute.scss';
import { BsPlusLg, BsXCircle } from 'react-icons/bs';
import { appInterface, attributeInterface } from 'interfaces';
import { attributeController } from 'controllers';
import InputField from './inputField';
import SelectorField from './selectorField';

interface CustomAttributeProps {
  attributes?: appInterface.ICAttributes[];
  addNewAttribute: Function;
  removeAttribute: Function;
}

function CustomAttribute({ attributes, addNewAttribute, removeAttribute }: CustomAttributeProps) {
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [attributeOptions, setAttributeOptions] = useState<attributeInterface.IAttribute[]>();
  const [newAttribute, setNewAttribute] = useState<appInterface.ICAttributes>({
    name: '',
    value: '',
  });

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setNewAttribute({
      ...newAttribute,
      [e.target.name]: value,
    });
  }

  function clear() {
    setNewAttribute({ name: '', value: '' });
    setIsAdding(false);
  }

  const fetchAttributes = async () => {
    const attributesFetch = await attributeController.getCustomAttributes();
    setAttributeOptions(attributesFetch);
  };

  useEffect(() => {
    fetchAttributes();
  }, []);

  return (
    <div className="customAttributesWrapper">
      <span className="cATitle">Additional Information</span>
      <div className="attributeWrapper">
        {attributes?.map((attribute) => (
          <div key={attribute.id} className="attributeBox">
            <span className="attributeName">{attribute.name}</span>
            <span className="attributeValue">{attribute.value}</span>
            <BsXCircle className="deleteAttribute" onClick={() => removeAttribute(attribute)} />
          </div>
        ))}
      </div>
      {isAdding ? (
        <div className="newAttributeBox">
          <SelectorField
            name="attributeType"
            label="Attribute Type"
            options={attributeOptions?.map((o) => ({
              value: o.name,
              label: o.name,
            }))}
            value={{
              value: newAttribute.name || '',
              label: newAttribute.name || '',
            }}
            handleChange={(attributeVariant: { value: string; label: string }) =>
              setNewAttribute({ ...newAttribute, name: attributeVariant.value })
            }
          />
          <InputField name="value" label="Value" value={newAttribute.value} handleChange={(e) => handleChange(e)} />
          <div className="buttonBox">
            <button
              type="button"
              onClick={() => {
                clear();
                addNewAttribute(newAttribute);
              }}
              className="cta"
            >
              Add Attribute
            </button>
            <button type="button" onClick={clear} className="cta danger">
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <BsPlusLg className="addIcon" onClick={() => setIsAdding(true)} />
      )}
    </div>
  );
}

export default CustomAttribute;
