import React, { useState } from 'react';
import { ISubscription, IUpdateSubscription } from 'interfaces/userInterface';
import styles from './Subscription.module.scss';
import { IconButton, Tooltip } from '@mui/material';
import { subscriptionsController } from 'controllers';
import { useSnackbar } from 'notistack';
import { FiMoreHorizontal, FiPlus } from 'react-icons/fi';
import { EditSubscription } from '../EditSubscription';
import { Link } from 'react-router-dom';
import { slugify } from 'utils/slugify';

interface SubscriptionProps {
  subscription: ISubscription;
  getSubscriptions?: () => void;
  editable?: boolean;
  addSubscription?: (subscription: ISubscription) => void;
}

export const Subscription = ({ subscription, getSubscriptions, editable, addSubscription }: SubscriptionProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [editMode, setEditMode] = useState(false);

  const deleteSubscription = async () => {
    try {
      await subscriptionsController.deleteSubscription({ subscriptionId: subscription.uuid });
      if (getSubscriptions) await getSubscriptions();
      setEditMode(false);
      enqueueSnackbar('Subscription deleted', { variant: 'success' });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const saveSubscription = async (subscriptionData: IUpdateSubscription) => {
    try {
      await subscriptionsController.editSubscription({
        ...subscriptionData,
        currency: subscriptionData.currency?.value,
      });
      if (getSubscriptions) await getSubscriptions();
      setEditMode(false);
      enqueueSnackbar('Subscription updated', { variant: 'success' });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <div className={styles.subscription}>
      {editMode ? (
        <EditSubscription
          subscriptionToEdit={subscription}
          deleteSubscription={deleteSubscription}
          saveSubscription={saveSubscription}
          cancelEdit={() => setEditMode(false)}
        />
      ) : (
        <div className={styles.subscriptionWrap}>
          <div className={styles.block}>
            <div className={styles.block_text_container}>
              <Link to={`/app/${slugify(subscription.app.title)}/${subscription.app.uuid}`}>
                <img src={subscription.app?.imageUrl || ''} className={styles.appIcon} alt="App Icon" />
              </Link>
              <Link to={`/app/${slugify(subscription.app.title)}/${subscription.app.uuid}`}>
                <p>{subscription.app?.title}</p>
              </Link>
            </div>
            <div className={styles.subscriptionWrap_button_container}>
              {editable && (
                <Tooltip className={styles.deleteIconWrapper} title="Edit Subscription">
                  <IconButton onClick={() => setEditMode(true)}>
                    <FiMoreHorizontal className={styles.deleteIcon} />
                  </IconButton>
                </Tooltip>
              )}
              {addSubscription && (
                <Tooltip className={styles.deleteIconWrapper} title="Add Subscription">
                  <IconButton onClick={() => addSubscription(subscription)}>
                    <FiPlus className={styles.deleteIcon} />
                  </IconButton>
                </Tooltip>
              )}
              {subscription.cost && (
                <p className={styles.price}>
                  {subscription.cost} {subscription.currency} / {subscription.interval}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      {subscription.note && !editMode && (
        <div>
          <p className={styles.note}>{subscription.note}</p>
        </div>
      )}
    </div>
  );
};
