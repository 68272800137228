/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { ISubscription, ISubscriptionTotal, IUser } from 'interfaces/userInterface';
import { subscriptionsController } from 'controllers';
import { AddSubscription } from 'components/subscriptions/AddSubscription';
import { Subscription } from 'components/subscriptions/Subscription';
import styles from './SubscriptionsPage.module.scss';
import { SubscriptionsHeader } from 'components/subscriptions/SubscriptionsHeader';
import { Button } from 'components/generic/Button';
import { SuggestedSubscriptions } from 'components/subscriptions/SuggestedSubscriptions';
import { Helmet } from 'react-helmet';
import { BsXLg } from 'react-icons/bs';
import { Modal } from 'components/generic';
import { handleEmailIteration } from 'helpers/emailToastHelper';
import { useSearchParams } from 'react-router-dom';
import DynamicIcon from 'components/SvgRender/SvgRender';

interface SubscriptionsPageProps {
  userData: IUser;
  reloadUser: () => void;
}

function SubscriptionsPage({ userData, reloadUser }: SubscriptionsPageProps) {
  const [searchParams] = useSearchParams();
  const emailData = useMemo(
    () => ({
      frequency: userData?.config?.subscriptions?.emailFrequency?.name,
      time: handleEmailIteration(userData?.config?.subscriptions?.emailFrequency?.sentTime),
      currency: userData?.config?.subscriptions?.preferredCurrency?.name || 'USD',
    }),
    [userData]
  );
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [showSuggestedSubscriptions, setShowSuggestedSubscriptions] = useState<boolean>(false);
  const [suggestedSubscriptions, setSuggestedSubscriptions] = useState<ISubscription[]>([]);
  const [totals, setTotals] = useState<ISubscriptionTotal[]>([]);
  const [total, setTotal] = useState<string>();
  const [addMode, setAddMode] = useState<boolean>(false);
  const [search, setSearch] = useState<string>(searchParams.get('subApp') || '');
  const [searchResults, setSearchResults] = useState<ISubscription[]>([]);
  const [showModal, setShowModal] = useState(false);

  const getSubscriptions = async () => {
    try {
      const subscriptionsFetch = await subscriptionsController.getSubscriptions();
      if (!!subscriptionsFetch.subscriptions.length) {
        setSubscriptions(subscriptionsFetch.subscriptions);
        setSearchResults(subscriptionsFetch.subscriptions);
      }
      const suggestedSubscriptionsFetch = await subscriptionsController.getSuggestedSubscriptions();
      if (!subscriptionsFetch.subscriptions.length && !!suggestedSubscriptionsFetch.subscriptions.length)
        setShowSuggestedSubscriptions(true);
      setSuggestedSubscriptions(suggestedSubscriptionsFetch.subscriptions);
      if (
        subscriptionsFetch.totals.length === 1 &&
        subscriptionsFetch.totals[0].currency === userData.config?.subscriptions?.preferredCurrency?.id
      )
        setTotals([]);
      else setTotals(subscriptionsFetch.totals);
      setTotal(subscriptionsFetch.total);
    } catch (error: any) {
      console.error(error);
    }
  };

  const searchSubscriptions = () => {
    setSearchResults(
      subscriptions.filter((subscription) => {
        return subscription.app.title.toLowerCase().includes(search.toLowerCase());
      })
    );
  };

  useEffect(() => searchSubscriptions(), [search, searchResults.length]);

  useEffect(() => {
    getSubscriptions();
  }, [userData]);

  return (
    <div className={styles.subscriptions}>
      <Helmet>
        <title>Subscriptions - Slocco</title>
      </Helmet>
      <h1 style={{ margin: 0, padding: '30px 15px 15px 15px' }}>{total}</h1>
      <span>per month for {subscriptions.length} subscriptions</span>
      {emailData.currency && emailData.frequency && (
        <span>
          You are currently receiving {emailData.frequency} emails {emailData.time && `on ${emailData.time} `}in{' '}
          {emailData.currency}.
        </span>
      )}
      {showModal && (
        <Modal hideModal={() => setShowModal(false)}>
          <SubscriptionsHeader reloadUser={reloadUser} total={total} totals={totals} userData={userData} />
        </Modal>
      )}
      {addMode && (
        <AddSubscription
          currency={userData.config?.subscriptions?.preferredCurrency?.id || 'USD'}
          setMode={() => setAddMode(false)}
          reload={getSubscriptions}
        />
      )}
      {addMode ? (
        <Button label="Cancel" className={styles.addToggle} onClick={() => setAddMode(false)} />
      ) : (
        <div className={styles.subscriptions_button_containers}>
          <Button label="Add new subscription" className={styles.addToggle} onClick={() => setAddMode(true)} />
          <Button
            icon={<DynamicIcon name="gear" width={30} height={30} />}
            className={styles.subscriptions_gear_icon}
            onClick={() => setShowModal(true)}
          ></Button>
        </div>
      )}
      <div className={styles.searchSubscription}>
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
          className="searchField"
          style={{ marginTop: '10px' }}
          placeholder="Search your subscriptions"
        />
        {search.length > 0 && <BsXLg className={styles.clearIcon} onClick={() => setSearch('')} />}
      </div>
      <div className={styles.subscriptionList}>
        {searchResults
          .filter((subscription) => !!subscription.app)
          .map((subscription) => (
            <Subscription
              editable
              key={subscription.uuid}
              getSubscriptions={getSubscriptions}
              subscription={subscription}
            />
          ))}
      </div>
      {!!suggestedSubscriptions.length && (
        <Button
          label="Show Popular Subscriptions"
          className={styles.popularSubsButton}
          onClick={() => setShowSuggestedSubscriptions(true)}
        />
      )}
      {showSuggestedSubscriptions && !!suggestedSubscriptions.length && (
        <SuggestedSubscriptions
          userData={userData}
          close={() => setShowSuggestedSubscriptions(false)}
          getSubscriptions={getSubscriptions}
          subscriptions={suggestedSubscriptions}
        />
      )}
    </div>
  );
}

export default SubscriptionsPage;
