/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import 'styles/pages/publicListsPage.scss';
import { useSnackbar } from 'notistack';
import { listController } from 'controllers';
import { listInterface, pageInterface, userInterface } from 'interfaces';
import { ListTable } from 'components/list';
import { BsXLg } from 'react-icons/bs';
import { pageSize } from 'utils/constants';
import { Helmet } from 'react-helmet';

function PublicListsPage({
  userData,
  reloadUser,
  followed,
}: {
  userData?: userInterface.IUser;
  reloadUser?: Function;
  followed?: boolean;
}) {
  const [lists, setLists] = useState<listInterface.IList[]>();
  const [search, setSearch] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const canLoadMore = total > page * pageSize;

  async function getLists({ searchByName }: { searchByName?: boolean }) {
    let listSearch: pageInterface.IPage<listInterface.IList>;
    try {
      if (searchByName)
        listSearch = await listController.getPublicListsPaginated({
          followed,
          name: search,
          page,
        });
      else listSearch = await listController.getPublicListsPaginated({ followed });
      if (userData && reloadUser) await reloadUser();
      setLists(listSearch.items);
      setTotal(listSearch.total);
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  async function getListsPaginated({ searchByName }: { searchByName?: boolean }) {
    let listSearch: pageInterface.IPage<listInterface.IList>;
    try {
      if (searchByName)
        listSearch = await listController.getPublicListsPaginated({
          followed,
          name: search,
          page: page + 1,
        });
      else
        listSearch = await listController.getPublicListsPaginated({
          followed,
          page: page + 1,
        });
      if (userData && reloadUser) await reloadUser();
      if (lists) setLists([...lists, ...listSearch.items]);
      setPage(page + 1);
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    if (search.length > 2) getLists({ searchByName: true });
    else getLists({});
  }, [search]);

  return (
    <div className="publicListsPage">
      <Helmet>
        <title>Favourite Lists - Slocco</title>
      </Helmet>
      <h1 className="title">Slocco - {followed ? 'Favourite' : 'All Public'} Lists</h1>
      <div className="searchBox">
        <div className="searchContainer">
          <input
            placeholder="Search lists..."
            className="searchField"
            value={search}
            onChange={(v) => setSearch(v.target.value)}
          />
          {search.length > 0 && <BsXLg className="clearIcon" onClick={() => setSearch('')} />}
        </div>
      </div>
      <ListTable
        hideBadge
        allowFollowing
        userData={userData}
        reloadUser={reloadUser ? () => reloadUser() : undefined}
        reloadLists={() => getLists({})}
        lists={lists}
      />
      {canLoadMore ? (
        <button
          type="button"
          className="cta"
          onClick={() => {
            if (search.length > 2) getListsPaginated({ searchByName: true });
            else getListsPaginated({});
          }}
        >
          Load More
        </button>
      ) : null}
    </div>
  );
}

export default PublicListsPage;
