import React from 'react';
import { css } from '@emotion/react';
import BeatLoader from 'react-spinners/BeatLoader';
import 'styles/components/generic/loadingSpinner.scss';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function LoadingSpinner() {
  return (
    <div className="loadingSpinner">
      <BeatLoader color="#7487ff" loading css={override} size={20} />
    </div>
  );
}

export default LoadingSpinner;
