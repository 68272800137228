import React from 'react';
import { useSnackbar } from 'notistack';
import ReactCodeInput from 'react-code-input';
import { userController } from 'controllers';
import 'styles/components/generic/emailVerification.scss';

function EmailVerification({
  email,
  verifyEmail,
  verificationCode,
  setVerificationCode,
}: {
  email?: string;
  verifyEmail: Function;
  verificationCode: string;
  setVerificationCode: React.Dispatch<React.SetStateAction<string>>;
}) {
  const { enqueueSnackbar } = useSnackbar();

  async function resendCode() {
    try {
      if (email) await userController.requestPassReset({ email });
      else await userController.resendCode();
      return enqueueSnackbar('Code sent', { variant: 'success' });
    } catch (error: any) {
      return enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  return (
    <div className="emailVerification">
      <h1 className="title">Please verify your email</h1>
      <ReactCodeInput
        inputMode="numeric"
        className="codeInput"
        value={verificationCode}
        onChange={setVerificationCode}
        name="code"
        type="number"
        fields={6}
      />
      <button type="button" onClick={() => resendCode()} className="resend">
        Resend Code
      </button>
      <button type="button" onClick={() => verifyEmail()} className="cta">
        Verify Email
      </button>
    </div>
  );
}

export default EmailVerification;
