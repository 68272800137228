import React, { useState, useEffect } from 'react';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import 'styles/components/generic/passwordInputField.scss';

function PasswordInputField({
  name,
  label,
  required,
  value,
  handleChange,
}: {
  name: string;
  label?: string;
  required?: boolean;
  value?: string | number;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [fieldType, setFieldType] = useState('password');

  useEffect(() => {
    if (showPassword) setFieldType('text');
    else setFieldType('password');
  }, [showPassword]);

  return (
    <div className="passwordInputField">
      {label && (
        <label htmlFor={name} className="label">
          {label}
        </label>
      )}
      <div className="fieldBox">
        <input
          autoComplete="off"
          id={name}
          name={name}
          required={required}
          type={fieldType}
          className="input"
          value={value}
          onChange={handleChange}
        />
        {!showPassword ? (
          <AiOutlineEye onClick={() => setShowPassword(true)} className="icon" />
        ) : (
          <AiOutlineEyeInvisible onClick={() => setShowPassword(false)} className="icon active" />
        )}
      </div>
    </div>
  );
}

export default PasswordInputField;
