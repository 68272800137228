import React, { useState } from 'react';
import { Button, ButtonGroup, TextField } from '@material-ui/core';
import { ISubscription, IUpdateSubscription, SubscriptionPlan } from 'interfaces/userInterface';
import { InputField, SelectorField } from 'components/generic';
import { LabelValue } from 'interfaces/generic';
import { AppList } from 'components/apps';
import styles from './CloneSubscription.module.scss';
import { currencyOptions } from 'utils/constants';

interface AddSubscriptionProps {
  subscriptionToEdit: ISubscription;
  saveSubscription: (subscription: IUpdateSubscription) => void;
  userCurrency?: string;
}

const billingIntervals = {
  month: { value: 'month', label: 'Month' },
  year: { value: 'year', label: 'Year' },
};

export const CloneSubscription = ({ subscriptionToEdit, saveSubscription, userCurrency }: AddSubscriptionProps) => {
  const [subscriptionData, setSubscriptionData] = useState<IUpdateSubscription>({
    ...subscriptionToEdit,
    appId: subscriptionToEdit?.app?.uuid,
    currency: {
      value: userCurrency || subscriptionToEdit.currency || 'USD',
      label: userCurrency || subscriptionToEdit.currency || 'USD',
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setSubscriptionData((s) => ({ ...s, [e.target.name]: e.target.value }));
  };

  return (
    <div className={styles.editSubscription}>
      <AppList noNavigate apps={[subscriptionToEdit.app]} />
      {subscriptionData.plan === SubscriptionPlan.Paid && (
        <>
          <div className={styles.priceBox}>
            <InputField name="cost" label="Cost" value={subscriptionData.cost} handleChange={handleChange} />
            <SelectorField
              name="currency"
              label="Currency"
              options={currencyOptions}
              value={subscriptionData.currency}
              handleChange={(e: LabelValue) => setSubscriptionData((s) => ({ ...s, currency: e }))}
            />
            <SelectorField
              name="billing-frequency"
              label="Billing Frequency"
              options={Object.values(billingIntervals)}
              value={billingIntervals[subscriptionData.interval]}
              handleChange={(e: LabelValue) =>
                setSubscriptionData({ ...subscriptionData, interval: e.value as 'month' | 'year' })
              }
            />
          </div>
          <div className={styles.priceBox}></div>
        </>
      )}

      <TextField
        label="Subscription Note"
        placeholder="Enter a note (up to 100 characters)"
        variant="outlined"
        fullWidth
        value={subscriptionData.note}
        onChange={(e) => setSubscriptionData((s) => ({ ...s, note: e.target.value }))}
        inputProps={{
          maxLength: 100,
        }}
        InputLabelProps={{
          style: { color: 'white' },
        }}
        InputProps={{
          style: { color: 'white' },
        }}
        className={styles.noteField}
      />

      <ButtonGroup className={styles.buttonGroup}>
        <Button onClick={() => saveSubscription(subscriptionData)} variant="contained" color="primary">
          Save
        </Button>
      </ButtonGroup>
    </div>
  );
};
