import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { appInterface } from 'interfaces';
import { CustomAttribute, InputField, SelectorField, UploadBox } from 'components/generic';
import { categoryOptions, platformOptionsKeys } from 'utils/constants';
import 'styles/pages/newAppPage.scss';
import { appHelper } from 'helpers';
import { appController, fileController, tagController } from 'controllers';
import { AppPlatforms } from 'components/apps';
import { ISelectorState } from 'interfaces/selector';
import { useSnackbar } from 'notistack';
import { validatedNewApp } from 'utils/validateNewApp';
import { Helmet } from 'react-helmet';

import { IExternalParsedApp, Platform, PlatformSelector } from 'interfaces/appInterface';
import { SavablePlatform } from 'components/search';

interface NewAppPageProps {
  isLoggedIn?: boolean;
}

function NewAppPage({ isLoggedIn }: NewAppPageProps) {
  const navigate = useNavigate();
  const [appData, setAppData] = useState<appInterface.INewApp>({});
  const [selectedCategories, setSelectedCategories] = useState<ISelectorState[]>();
  const [tagOptions, setTagOptions] = useState<ISelectorState[]>();
  const [selectedTags, setSelectedTags] = useState<ISelectorState[]>();
  const [image, setImage] = useState<{ name?: string; file?: File }>();
  const [externalImageUrl, setExternalImageUrl] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();

  const [preSelectedPlatform, setPreSelectedPlatform] = useState<PlatformSelector | null>(null);

  const [apps, setApps] = useState<IExternalParsedApp[]>();
  const [loading, setLoading] = useState(false);

  async function findApps() {
    if (!preSelectedPlatform || !appData?.title) return;

    try {
      setLoading(true);
      setApps(
        await appController.searchExtermalByName({
          name: appData.title,
          country: appData.country?.value ?? 'US',
          platform: preSelectedPlatform?.value as Platform,
        })
      );
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setApps([]);
    if (preSelectedPlatform) findApps();
  }, [preSelectedPlatform, appData.country]);

  useEffect(() => {
    setApps([]);
  }, [preSelectedPlatform]);

  useEffect(() => {
    if (!!appData.platforms?.itunes && !appData.platforms?.android) {
      setPreSelectedPlatform({
        value: Platform.android,
        label: platformOptionsKeys.android,
      });
    } else if (!!appData.platforms?.android && !appData.platforms?.itunes) {
      setPreSelectedPlatform({
        value: Platform.itunes,
        label: platformOptionsKeys.itunes,
      });
    } else setPreSelectedPlatform(null);
  }, [appData.platforms?.android, appData.platforms?.itunes]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setAppData({
      ...appData,
      [e.target.name]: value,
    });
  }

  async function fetchTags() {
    const tagOptionsFetch = await tagController.getTags();
    setTagOptions(tagOptionsFetch.map((tag) => ({ label: tag, value: tag })));
  }

  function removePlatform(removePlatformType: appInterface.Platform) {
    if (appData && appData.platforms) {
      const platformHolder = appData.platforms;
      delete platformHolder[removePlatformType];
      setAppData({ ...appData, platforms: platformHolder });
    }
  }

  useEffect(() => {
    fetchTags();
  }, []);

  useEffect(() => {
    const fixTags = () => {
      const fixedTags = appHelper.filterSelectorArray(selectedTags);
      setAppData((prevAppData) => ({ ...prevAppData, tags: fixedTags }));
    };
    if (selectedTags) fixTags();
  }, [selectedTags]);

  useEffect(() => {
    const fixCategories = () => {
      const fixedCategories = appHelper.filterSelectorArray(selectedCategories);
      setAppData((prevAppData) => ({
        ...prevAppData,
        categories: fixedCategories,
      }));
    };
    if (selectedCategories) fixCategories();
  }, [selectedCategories]);

  const setFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImage({
        name: e.target.files[0].name,
        file: e.target.files[0],
      });
      e.target.value = '';
    }
  };

  function addCustomAttribute(attributeData: appInterface.ICAttributes) {
    if (appData.customAttributes)
      setAppData({
        ...appData,
        customAttributes: [...appData.customAttributes, attributeData],
      });
    else setAppData({ ...appData, customAttributes: [attributeData] });
  }

  function removeCustomAttribute(attributeData: appInterface.ICAttributes) {
    if (appData.customAttributes) {
      const cleanedAttributes = appData.customAttributes.filter(
        (attribute) => attribute.name !== attributeData.name || attribute.value !== attributeData.value
      );
      setAppData({ ...appData, customAttributes: cleanedAttributes });
    }
  }

  async function createApp() {
    try {
      if (appData) {
        const validatedApp = validatedNewApp({ appData, image });
        if (validatedApp) return enqueueSnackbar(validatedApp, { variant: 'warning' });
        let fileResponse: string | undefined;
        if (image?.file && image.name) {
          const imageData = new FormData();
          imageData.append('file', image.file);
          imageData.append('fileName', image.name);
          fileResponse = await fileController.uploadFile(imageData);
          setAppData({ ...appData, imageUrl: fileResponse });
        }
        await appController.createApp({
          ...appData,
          imageUrl: externalImageUrl || fileResponse || '',
        });
        if (selectedTags)
          tagController.createTags(
            selectedTags.filter((selectedTag) => !tagOptions?.includes(selectedTag)).map((tag) => tag.value)
          );

        navigate('/');
      }
    } catch (error: any) {
      console.log('App creation failed', error);
    }
  }

  const isPlatformSelected = Object.values(appData.platforms || {}).reduce((v1, v2) => v1 || v2, false);
  const isInputLocked = Boolean(appData.platforms?.android) || Boolean(appData.platforms?.itunes);

  return (
    <div className="newAppPage">
      <Helmet>
        <title>New App - Slocco</title>
      </Helmet>
      {isPlatformSelected && (
        <div className="inputWrapper">
          <h1 className="title">Custom App</h1>
          <InputField
            disabled={isInputLocked}
            name="title"
            label="App Title"
            value={appData?.title}
            handleChange={(e) => handleChange(e)}
          />
          <InputField
            disabled={isInputLocked}
            name="description"
            label="Description"
            value={appData?.description}
            handleChange={(e) => handleChange(e)}
          />
          <InputField
            disabled={isInputLocked}
            name="sellerName"
            label="Vendor Name"
            value={appData?.sellerName}
            handleChange={(e) => handleChange(e)}
          />
          <SelectorField
            name="categories"
            isMulti
            label="Categories"
            options={categoryOptions}
            value={selectedCategories}
            handleChange={(categories: ISelectorState[]) => setSelectedCategories(categories)}
            disabled={isInputLocked}
          />
          <SelectorField
            isMulti
            name="tags"
            label="Tags"
            options={tagOptions}
            value={selectedTags}
            creatable
            handleChange={(tags: ISelectorState[]) => setSelectedTags(tags)}
          />
          <UploadBox
            name="appIcon"
            removeFile={() => setImage({ file: undefined, name: '' })}
            label="App Icon"
            previewImage={image}
            setFile={(e: React.ChangeEvent<HTMLInputElement>) => setFile(e)}
            disabled={isInputLocked}
          />
          <CustomAttribute
            attributes={appData?.customAttributes}
            addNewAttribute={(attributeData: appInterface.ICAttributes) => addCustomAttribute(attributeData)}
            removeAttribute={(attributeData: appInterface.ICAttributes) => removeCustomAttribute(attributeData)}
          />
          <hr className="splitter" />
          <button type="button" onClick={createApp} className="cta">
            Submit
          </button>
        </div>
      )}
      <div className="displayFlexColumn">
        <div className="platformWrapper">
          <h1 className="title">Platforms</h1>
          <AppPlatforms
            isLoggedIn={isLoggedIn}
            appName={appData.title}
            platforms={appData.platforms}
            removePlatform={(removePlatformType: appInterface.Platform) => removePlatform(removePlatformType)}
            addNewPlatform={() => null}
            onAdd={(platform, app, country) => {
              if (app) {
                if (!!app.platforms.android && appData.platforms?.itunes) {
                  setAppData({
                    ...appData,
                    platforms: { ...appData?.platforms, ...app.platforms },
                    country,
                  });
                } else {
                  setAppData({
                    ...app,
                    platforms: { ...appData?.platforms, ...app.platforms },
                    country,
                  });
                }
                setSelectedCategories(categoryOptions.filter((c) => app.categories.includes(c.value)));
                setExternalImageUrl(app.imageUrl);
              }
              if (platform) {
                const { newPlatformType, newPlatformData } = platform;
                setAppData({
                  ...appData,
                  platforms: {
                    ...appData?.platforms,
                    [newPlatformType]: newPlatformData,
                  },
                });
              }
            }}
          />
        </div>
        {preSelectedPlatform && (
          <div>
            {preSelectedPlatform.label}
            <SavablePlatform
              loading={loading}
              saveApp={(newAppData: appInterface.IExternalParsedApp) =>
                setAppData({
                  ...appData,
                  platforms: { ...appData?.platforms, ...newAppData.platforms },
                })
              }
              apps={apps}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default NewAppPage;
