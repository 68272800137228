import { INewApp } from 'interfaces/appInterface';

interface ValidatedNewAppProps {
  appData: INewApp;
  image?: { name?: string; file?: File };
}

export const validatedNewApp = ({ appData, image }: ValidatedNewAppProps): string | void => {
  if (!appData.title) return 'Name is required';
  if (!appData.description) return 'Description is required';
  if (!appData.sellerName) return 'Vendor name is required';
  if (!appData.categories) return 'Categories are required';
  if (!appData.imageUrl && !image?.file) return 'Image is required';
  return;
};
