/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import 'styles/pages/onboarding/newPasswordPage.scss';
import { PasswordInputField } from 'components/generic';
import { userController } from 'controllers';

function NewPasswordPage() {
  const navigate = useNavigate();
  const [passwordData, setPasswordData] = useState<{
    password?: string;
    confirmPassword?: string;
  }>();
  const { enqueueSnackbar } = useSnackbar();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setPasswordData({
      ...passwordData,
      [e.target.name]: value,
    });
  }

  async function handleRegister(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      if (passwordData?.password && passwordData.confirmPassword) {
        await userController.setNewPassword({
          password: passwordData.password,
          confirmPassword: passwordData.confirmPassword,
        });
      }
      enqueueSnackbar('Your password has been reset', { variant: 'success' });
      return navigate('/login');
    } catch (error: any) {
      return enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  return (
    <div className="newPasswordPage">
      <h1 className="title">Please enter your new password</h1>
      <form className="inputWrapper" onSubmit={(e) => handleRegister(e)}>
        <PasswordInputField
          name="password"
          required
          label="Password"
          value={passwordData?.password}
          handleChange={(e) => handleChange(e)}
        />
        <PasswordInputField
          name="confirmPassword"
          required
          label="Confirm Password"
          value={passwordData?.confirmPassword}
          handleChange={(e) => handleChange(e)}
        />
        <button type="submit" className="cta">
          Reset
        </button>
      </form>
    </div>
  );
}

export default NewPasswordPage;
