import { INewSubscription, ISubscription, IUpdateSubscription, IUser } from 'interfaces/userInterface';
import React, { useEffect, useState } from 'react';
import styles from './SuggestedSubscriptions.module.scss';
import { Modal } from 'components/generic';
import { CloneSubscription } from '../CloneSubscription';
import { subscriptionsController } from 'controllers';
import { useSnackbar } from 'notistack';

interface SuggestedSubscriptionsProps {
  subscriptions: ISubscription[];
  getSubscriptions: () => void;
  close: () => void;
  userData: IUser;
}

export const SuggestedSubscriptions = ({
  subscriptions,
  getSubscriptions,
  close,
  userData,
}: SuggestedSubscriptionsProps) => {
  const [suggestedSubscriptions, setSuggestedSubscriptions] = useState<ISubscription[]>(subscriptions || []);
  const { enqueueSnackbar } = useSnackbar();

  const addSubscription = async (subscriptionData: INewSubscription) => {
    try {
      await subscriptionsController.addSubscription({
        ...subscriptionData,
        currency: subscriptionData.currency?.value,
      });

      getSubscriptions();
      enqueueSnackbar('Subscription added', { variant: 'success' });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleCloneSubscription = async (subscription: IUpdateSubscription) => {
    await addSubscription(subscription);
    setSuggestedSubscriptions(suggestedSubscriptions.filter((s) => s.uuid !== subscription.uuid));
  };

  useEffect(() => {
    if (suggestedSubscriptions.length === 0) close();
  }, [suggestedSubscriptions]);

  return (
    <Modal hideModal={close}>
      <div className={styles.suggestedSubscriptions}>
        <h2>Suggested Subscriptions</h2>
        {suggestedSubscriptions.map((subscription) => (
          <CloneSubscription
            userCurrency={userData.config?.subscriptions?.preferredCurrency?.id}
            key={subscription.appId}
            subscriptionToEdit={subscription}
            saveSubscription={handleCloneSubscription}
          />
        ))}
      </div>
    </Modal>
  );
};
