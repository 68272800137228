import { attributeInterface } from 'interfaces';
import { errorHelper } from 'helpers';
import { getInstance } from 'utils/axiosInstance';

const backendURL = process.env.REACT_APP_BACKEND_URL;
const instance = getInstance(`${backendURL}/attributes`);

export async function getCustomAttributes(): Promise<attributeInterface.IAttribute[]> {
  try {
    const { data } = await instance.get('/');
    return data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}
