import React from 'react';
import 'styles/components/search/suggestions.scss';
import { appInterface, listInterface } from 'interfaces';
import { AppSearchComponent } from '.';
import ListSearchComponent from './listSearchComponent';

interface SuggestionsProps {
  apps?: appInterface.IApp[];
  lists?: listInterface.IList[];
  appClicked?: Function;
  listClicked?: Function;
}

function Suggestions({ apps, lists, appClicked, listClicked }: SuggestionsProps) {
  return (
    <div className={`suggestions ${(!!apps?.length || !!lists?.length) && 'active'}`}>
      {!!lists?.length &&
        lists.map((list) => (
          <ListSearchComponent listData={list} clickable listClicked={listClicked} key={list.uuid} />
        ))}
      {apps &&
        apps.length > 0 &&
        apps.map((app) => <AppSearchComponent key={app.uuid} appClicked={appClicked} clickable appData={app} />)}
    </div>
  );
}

export default Suggestions;
