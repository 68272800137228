import { IUser, IUserUpdate } from 'interfaces/userInterface';
import * as myLocalStorage from './myLocalStorage';
import { userController } from 'controllers';

export const getUser = async (): Promise<IUser> => {
  const storedUser = myLocalStorage.getUser();
  if (!storedUser) {
    return userController.getUser();
  }
  return storedUser;
};

export const updateUser = async ({ userUpdateData }: { userUpdateData: IUserUpdate }): Promise<IUser> => {
  const user = await getUser();
  await userController.updateUser({ userUpdateData });
  const updatedUser = Object.assign(user, userUpdateData);
  myLocalStorage.setUser(updatedUser);
  return updatedUser;
};
