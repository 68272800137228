import React from 'react';
import styles from './Announcement.module.scss';
import { AnnouncementType } from 'interfaces/appInterface';
import classNames from 'classnames';

interface Props {
  text: string;
  type: AnnouncementType;
}

export const Announcement = ({ text, type }: Props) => {
  if (!text) return null;
  return (
    <div className={styles.announcementBox}>
      <div
        className={classNames(styles.announcement, {
          [styles.warning]: type === AnnouncementType.Warning,
          [styles.error]: type === AnnouncementType.Error,
        })}
      >
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  );
};
