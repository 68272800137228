import React from 'react';
import { Helmet } from 'react-helmet';

export const TermsConditions = () => {
  return (
    <div style={{ maxWidth: 750, margin: '0 auto', textAlign: 'left' }}>
      <Helmet>
        <title>Terms & Conditions - Slocco</title>
      </Helmet>
      <h1>Terms and Conditions:</h1>
      <h3>1. Acceptance of Terms</h3>
      <p>
        These Terms and Conditions govern your use of the Slocco App. By using the Service, you agree to these Terms and
        Conditions, which may be updated from time to time. If you do not accept these Terms and Conditions, please do
        not use the Service.
      </p>
      <h3>2. Consent to Receive Emails</h3>
      <p>
        By registering and using the Service, you hereby provide your express consent to receive communications and
        promotions from us electronically, including email, text message, and other electronic messages.
      </p>
      <h3>3. Registration and Security</h3>
      <p>
        In order to use the Service, you must register with us by providing your name, email address, and any other
        personal information we require. You are responsible for maintaining the security of your passwords, and you
        agree not to disclose your passwords to any third party. You agree to accept responsibility for all activities
        that occur under your account or password.
      </p>
      <h3>4. Privacy Policy</h3>
      <p>
        We respect your privacy and are committed to protecting it. Our Privacy Policy explains how we collect, use,
        share and protect your personal information. It also describes the choices available to you regarding our use of
        your personal information and how you can access and update this information. We encourage you to review our
        Privacy Policy periodically so that you are familiar
      </p>
      <h3>5. User Content</h3>
      <p>
        You may post, upload, and/or submit content (including reviews, comments, and other materials) to the Service.
        You are solely responsible for your User Content and the consequences of posting or publishing it. By submitting
        your User Content, you agree that:
        <ul>
          <li>Your User Content does not violate any intellectual property rights or other third-party rights.</li>
          <li>
            Your User Content must not be illegal, obscene, threatening, defamatory, invasive of privacy, infringing of
            intellectual property rights, or otherwise injurious to third parties or objectionable.
          </li>
          <li>You are solely responsible for your User Content and any claims arising therefrom.</li>
          <li>You indemnify us for all claims brought against us related to your User Content.</li>
        </ul>
      </p>
    </div>
  );
};
