import { useSnackbar } from 'notistack';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import 'styles/pages/editAppPage.scss';
import { CustomAttribute, InputField, SelectorField, UploadBox } from 'components/generic';
import { appController, fileController, tagController } from 'controllers';
import { appInterface, appSuggestionInterface } from 'interfaces';
import { categoryOptions } from 'utils/constants';
import { ISelectorState } from 'interfaces/selector';
import { appHelper } from 'helpers';
import { Helmet } from 'react-helmet';
import { Button } from 'components/generic/Button';

interface EditAppPageProps {
  hideModal: Dispatch<SetStateAction<boolean>>;
}

export default function EditAppPage({ hideModal }: EditAppPageProps) {
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedCategories, setSelectedCategories] = useState<ISelectorState[]>();
  const [selectedTags, setSelectedTags] = useState<ISelectorState[]>();
  const [tagOptions, setTagOptions] = useState<ISelectorState[]>();
  const uuid: string | undefined = params.uuid;
  const [newAppData, setNewAppData] = useState<appSuggestionInterface.INewAppSuggestion>();
  const [existingApp, setExistingApp] = useState<appSuggestionInterface.INewAppSuggestion>();
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, setUpdating] = useState(false);
  const [customAttributes, setCustomAttributes] = useState<appInterface.ICAttributes[]>([]);
  const [image, setImage] = useState<{ name?: string; file?: File }>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNewAppData({
      ...newAppData,
      [e.target.name]: value,
    });
  };

  const getAppById = async () => {
    try {
      if (!uuid) return navigate('/');
      const appData: appInterface.IApp = (await appController.getAppById(uuid)).app;
      setNewAppData({ ...appData });
      setExistingApp({ ...appData });
      setSelectedCategories(appData.categories.map((c) => ({ label: c, value: c })));
      setCustomAttributes(appData.customAttributes ?? []);
      setSelectedTags(appData.tags?.map((t) => ({ label: t, value: t })));
      setIsLoading(false);
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const fetchTags = async () => {
    const tagOptionsFetch = await tagController.getTags();
    setTagOptions(tagOptionsFetch.map((tag) => ({ label: tag, value: tag })));
  };

  useEffect(() => {
    const fixTags = async () =>
      setNewAppData({
        ...newAppData,
        tags: (await appHelper.filterSelectorArray(selectedTags)) || [],
      });
    if (selectedTags) fixTags();
  }, [selectedTags]);

  useEffect(() => {
    const fixCategories = async () =>
      setNewAppData({
        ...newAppData,
        categories: (await appHelper.filterSelectorArray(selectedCategories)) || [],
      });
    if (selectedCategories) fixCategories();
  }, [selectedCategories]);

  useEffect(() => {
    setNewAppData({
      ...newAppData,
      customAttributes: customAttributes || [],
    });
  }, [customAttributes]);

  const updateAppById = async () => {
    try {
      if (!uuid) return navigate('/');
      setUpdating(true);
      let fileResponse: string | undefined;
      if (image?.file && image.name) {
        const imageData = new FormData();
        imageData.append('file', image.file);
        imageData.append('fileName', image.name);
        fileResponse = await fileController.uploadFile(imageData);
        setNewAppData((state) => ({ ...state, imageUrl: fileResponse }));
      }
      if (newAppData)
        await appController.suggestAppUpdateById(uuid, {
          ...newAppData,
          imageUrl: fileResponse || newAppData.imageUrl || '',
        });
      enqueueSnackbar('App suggestion submitted successfully', {
        variant: 'success',
      });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    setUpdating(false);
  };

  const addCustomAttribute = (attributeData: appInterface.ICAttributes) => {
    setCustomAttributes([...customAttributes, attributeData]);
  };

  const removeCustomAttribute = (attributeData: appInterface.ICAttributes) => {
    setCustomAttributes(customAttributes.filter((a) => a.name !== attributeData.name));
  };

  const setFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImage({
        name: e.target.files[0].name,
        file: e.target.files[0],
      });
      e.target.value = '';
    }
  };

  useEffect(() => {
    fetchTags();
    getAppById();
  }, []);

  if (isLoading || !newAppData) return <h1>Loading...</h1>;
  const isInputLocked = Boolean(newAppData.platforms?.android) || Boolean(newAppData.platforms?.itunes);

  return (
    <section>
      <div className="editAppPage">
        <Helmet>
          <title>Edit {newAppData.title} - Slocco</title>
        </Helmet>
        <h1 className="title">Edit - {newAppData.title}</h1>
        <img className="appImage" referrerPolicy="no-referrer" src={newAppData.imageUrl} alt={newAppData.title} />
        <div className="inputWrapper">
          <UploadBox
            name="appIcon"
            removeFile={() => setImage({ file: undefined, name: '' })}
            label="App Icon"
            previewImage={image}
            setFile={(e: React.ChangeEvent<HTMLInputElement>) => setFile(e)}
            disabled={!isInputLocked}
          />
          <InputField name="title" label="App Title" value={newAppData?.title} handleChange={(e) => handleChange(e)} />
          <InputField
            name="description"
            label="Description"
            value={newAppData?.description}
            handleChange={(e) => handleChange(e)}
          />
          <InputField
            name="sellerName"
            label="Vendor Name"
            value={newAppData?.sellerName}
            handleChange={(e) => handleChange(e)}
          />
          <SelectorField
            name="categories"
            isMulti
            label="Categories"
            options={categoryOptions}
            value={selectedCategories}
            handleChange={(categories: ISelectorState[]) => setSelectedCategories(categories)}
          />
          <SelectorField
            isMulti
            name="tags"
            label="Tags"
            value={selectedTags}
            options={tagOptions}
            creatable
            handleChange={(tags: ISelectorState[]) => setSelectedTags(tags)}
          />
          <CustomAttribute
            attributes={customAttributes}
            addNewAttribute={(attributeData: appInterface.ICAttributes) => addCustomAttribute(attributeData)}
            removeAttribute={(attributeData: appInterface.ICAttributes) => removeCustomAttribute(attributeData)}
          />
        </div>
        <Button
          disabled={isUpdating || (JSON.stringify(newAppData) === JSON.stringify(existingApp) && !image?.file)}
          onClick={() => {
            updateAppById();
            hideModal(false);
          }}
          type="primary"
        >
          SUBMIT
        </Button>
      </div>
    </section>
  );
}
