import React, { useState, useEffect } from 'react';
import 'styles/components/apps/appPlatforms.scss';
import { BsXLg } from 'react-icons/bs';
import { appInterface } from 'interfaces';
import { appController } from 'controllers';
import { useSnackbar } from 'notistack';
import { SavablePlatform } from 'components/search';
import { IExternalParsedApp } from 'interfaces/appInterface';
import { SelectorField } from 'components/generic';
import { appStoreCountries } from 'utils/constants';

function ExternalAppSearch({
  platform,
  appName,
  addNewPlatform,
}: {
  platform: appInterface.Platform;
  appName?: string;
  addNewPlatform: Function;
}) {
  const [search, setSearch] = useState<string>(appName || '');
  const [apps, setApps] = useState<IExternalParsedApp[]>();
  const [country, setCountry] = useState<{ value: string; label: string }>({
    value: 'us',
    label: 'United States of America',
  });
  const { enqueueSnackbar } = useSnackbar();

  async function findApps() {
    try {
      setApps(
        await appController.searchExtermalByName({
          name: search,
          country: country.value,
          platform,
        })
      );
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  useEffect(() => {
    setApps([]);
    if (search) findApps();
  }, [platform, country]);

  useEffect(() => {
    setApps([]);
  }, [search]);

  return (
    <div className="searchBox">
      <SelectorField
        name="country"
        label="Country"
        options={Object.keys(appStoreCountries).map((countryCode) => ({
          value: countryCode,
          label: appStoreCountries[countryCode],
        }))}
        value={country}
        handleChange={(newCountry: { value: string; label: string }) => setCountry(newCountry)}
      />
      <div className="searchContainer">
        <input
          className="searchField"
          value={search}
          placeholder="App Name"
          onChange={(v) => setSearch(v.target.value)}
        />
        {search?.length > 0 && <BsXLg className="clearIcon" onClick={() => setSearch('')} />}
      </div>
      <div className="appBox">
        {apps && apps?.length > 0 && (
          <SavablePlatform
            saveApp={(appData: appInterface.IExternalParsedApp) =>
              addNewPlatform({ newPlatformData: appData, country })
            }
            apps={apps}
          />
        )}
      </div>
      <button type="button" onClick={() => findApps()} className="cta">
        Search
      </button>
    </div>
  );
}

export default ExternalAppSearch;
