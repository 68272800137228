import React from 'react';
import 'styles/components/search/suggestions.scss';
import { appInterface } from 'interfaces';
import { PlatformSearchSaveComponent, NoSearchResults } from '.';
import { LoadingSpinner } from 'components/generic';

function SavablePlatform({
  apps,
  saveApp,
  inList,
  loading,
}: {
  apps?: appInterface.IExternalParsedApp[] | appInterface.IApp[];
  saveApp: Function;
  inList?: boolean;
  loading?: boolean;
}) {
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className={`suggestions ${apps && 'active'}`}>
          {apps && apps.length > 0 ? (
            apps.map((app) => (
              <PlatformSearchSaveComponent
                key={app.uuid}
                inList={inList}
                appData={app}
                saveApp={(appId: appInterface.IExternalParsedApp | appInterface.IApp) => saveApp(appId)}
              />
            ))
          ) : (
            <NoSearchResults />
          )}
        </div>
      )}
    </>
  );
}

export default SavablePlatform;
