import React from 'react';
import 'styles/components/list/publicBadge.scss';

function PublicBadge({ isPrivate }: { isPrivate?: boolean }) {
  return (
    <div className={isPrivate ? 'privateBadge' : 'publicBadge'}>
      <span className="text">{isPrivate ? 'private' : 'public'}</span>
    </div>
  );
}

export default PublicBadge;
