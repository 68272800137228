export enum Status {
  Approved = 'Approved',
  Pending = 'Pending',
  Banned = 'Banned',
  Reported = 'Reported',
}

export enum Platform {
  itunes = 'itunes',
  android = 'android',
  windows = 'windows',
  mac = 'mac',
  linux = 'linux',
  web = 'web',
}

export interface IReport {
  userId: string;
  message: string;
  timestamp: Date;
}

export interface IPlatform {
  id?: string;
  url?: string;
  type?: Platform;
  requestId?: string;
  sellerUrl?: string;
  sellerName?: string;
  icon?: string;
  rating?: number;
  ratingCount?: number;
  approved?: boolean;
  price?: number;
  version?: string;
}

export interface PlatformSelector {
  value: Platform;
  label: string;
}

export interface IPlatforms {
  itunes?: IPlatform;
  android?: IPlatform;
  windows?: IPlatform;
  mac?: IPlatform;
  linux?: IPlatform;
  web?: IPlatform;
  requests?: IPlatform[];
}

export enum AnnouncementType {
  Error = 'error',
  Warning = 'warning',
}

interface IAnnouncement {
  type: AnnouncementType;
  text: string;
}

interface ILink {
  type: string;
  link: string;
}

export interface IApp {
  uuid: string;
  platforms: IPlatforms;
  title: string;
  description: string;
  categories: string[];
  inList?: boolean;
  added?: boolean;
  tags?: string[];
  status?: Status;
  reports?: IReport[];
  imageUrl: string;
  screenshots?: string[];
  releaseDate?: string;
  sellerName: string;
  customAttributes?: ICAttributes[];
  rating?: number;
  ratingCount?: number;
  userId?: string;
  timestamp: Date;
  url?: string;
  announcement?: IAnnouncement;
  links: ILink[];
}

export interface IMiniApp {
  uuid: string;
  title: string;
  description: string;
}
export interface IPreviewApp {
  uuid: string;
  title: string;
  description: string;
  sellerName: string;
  tags: string[];
  categories: string[];
  platforms: IPlatforms;
  imageUrl: string;
}

export interface IExternalParsedApp {
  uuid: string;
  platforms: IPlatforms;
  title: string;
  description: string;
  inList?: boolean;
  categories: string[];
  added?: boolean;
  tags?: string[];
  status?: Status;
  reports?: IReport[];
  imageUrl: string;
  screenshots?: string[];
  rating?: number;
  ratingCount?: number;
  releaseDate?: string;
  sellerName: string;
  version?: string;
}

export interface INewApp {
  title?: string;
  description?: string;
  categories?: string[];
  platforms?: IPlatforms;
  tags?: string[];
  status?: Status;
  imageUrl?: string;
  xlImageUrl?: string;
  screenshots?: string[];
  releaseDate?: string;
  sellerUrl?: string;
  sellerName?: string;
  customAttributes?: ICAttributes[];
  country?: { value: string; label: string };
}

export interface INewPlatform {
  newPlatformType: Platform;
  newPlatformData: IPlatform;
}

export interface IStoreApp {
  trackId: number;
  trackName: string;
  trackViewUrl: string;
  description: string;
  artworkUrl100: string;
}

export interface ICAttributes {
  id?: string;
  name?: string;
  value?: string;
}
