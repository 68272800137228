import React from 'react';
import styles from './chip.module.scss';
import classNames from 'classnames';

export function Chip({ type, children, classname, onClick, style }: any) {
  const chipStyles = classNames(styles.chip, type === 'category' && styles.chip_category, classname);
  return (
    <button className={chipStyles} onClick={() => onClick()} style={style}>
      {type === 'category' ? children : `#${children}`}
    </button>
  );
}
