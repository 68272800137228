/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { AiOutlineStar, AiTwotoneStar } from 'react-icons/ai';
import 'styles/components/list/listPreview.scss';
import { appInterface, listInterface, userInterface } from 'interfaces';
import { AppList } from 'components/apps';
import { appController, userController } from 'controllers';
import PublicBadge from 'components/list/publicBadge';
import { useNavigate } from 'react-router-dom';
import { slugify } from 'utils/slugify';

interface ListPreviewProps {
  hideBadge?: boolean;
  allowFollowing?: boolean;
  userData?: userInterface.IUser;
  listData: listInterface.IList | listInterface.INewsFeedList;
  reloadLists: Function;
  reloadUser?: Function;
  listIsOpen?: boolean;
}

function ListPreview({ hideBadge, allowFollowing, listData, reloadUser, listIsOpen }: ListPreviewProps) {
  const [apps, setApps] = useState<appInterface.IApp[]>();
  const [ownerData, setOwnerData] = useState<userInterface.IUserPublic>();
  const [loading, setLoading] = useState<boolean>(true);
  const [, setShowModal] = useState(listIsOpen);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  async function fetchApps() {
    let appFetch: appInterface.IApp[];

    try {
      if (typeof listData.apps[0] === 'object') setApps(listData.apps as appInterface.IApp[]);
      else {
        appFetch = await appController.getManyAppsById(listData.apps as string[], 4);
        setApps(appFetch);
      }
      return setLoading(false);
    } catch (error: any) {
      setLoading(false);
      return enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  async function fetchOwner() {
    let ownerFetch: userInterface.IUserPublic;

    try {
      if (listData.owner) return setOwnerData(listData.owner);
      ownerFetch = await userController.getPublicUserById({
        userId: listData.userId,
      });
      setOwnerData(ownerFetch);
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  async function followList({ unfollow }: { unfollow?: boolean }) {
    try {
      await userController.followList({ listId: listData.uuid, unfollow });
      if (unfollow) enqueueSnackbar('List has been unfollowed', { variant: 'success' });
      else enqueueSnackbar('List has been followed', { variant: 'success' });
      if (reloadUser) reloadUser();
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    fetchApps();
    fetchOwner();
  }, [listData.apps, listData.userId]);

  useEffect(() => {
    setShowModal(listIsOpen);
  }, [listIsOpen]);

  return (
    <>
      {/* {showModal && (
        <ListModal
          deleteList={() => deleteList()}
          userData={userData}
          ownerData={ownerData}
          updateLists={() => reloadLists()}
          hideModal={() => setShowModal(false)}
          appsData={apps}
          listData={listData}
        />
      )} */}
      <div className="listPreview" key={listData.uuid}>
        <div className="listTextBox">
          <div
            onClick={() => navigate(`/list/${slugify(listData.name)}/${listData.uuid}`)}
            tabIndex={0}
            onKeyDown={() => false}
            role="button"
            className="listUrl"
          >
            <h1 className="title">{listData.name}</h1>
            <span className="user">{ownerData?.username}</span>
            {/* <span className="counter">{listData.apps.length} Apps</span> */}
          </div>
          {allowFollowing &&
            (listData.isFollowed ? (
              <AiTwotoneStar onClick={() => followList({ unfollow: true })} className="followIcon" />
            ) : (
              <AiOutlineStar onClick={() => followList({})} className="followIcon" />
            ))}
          {listData.isPublic && !hideBadge && <PublicBadge />}
          {!listData.isPublic && !hideBadge && <PublicBadge isPrivate />}
        </div>
        <div className="listAppBox">{apps && listData && !loading && <AppList apps={apps} />}</div>
      </div>
    </>
  );
}

export default ListPreview;
