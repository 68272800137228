import { errorHelper } from 'helpers';
import { getAuthInstance } from 'utils/axiosInstance';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const authInstance = getAuthInstance(`${backendURL}/files`);

export async function uploadFile(fileData: FormData) {
  try {
    const response = await authInstance.post('/image', fileData);
    return response.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}
