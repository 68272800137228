import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { SavableSuggestions } from 'components/search';
import { appController } from 'controllers';
import { appHelper } from 'helpers';
import { IApp, IExternalParsedApp } from 'interfaces/appInterface';
import { InputField } from 'components/generic';

interface AppSearchProps {
  existingAppIds: string[];
  saveApp: (appData: IApp) => void;
  addApp: boolean;
}

export const AppSearch = ({ existingAppIds, saveApp, addApp }: AppSearchProps) => {
  const [appSearch, setAppSearch] = useState<string>('');
  const [suggestions, setSuggestions] = useState<IApp[] | IExternalParsedApp[]>();
  const { enqueueSnackbar } = useSnackbar();

  const searchLocalApps = async () => {
    let suggestionSearch: IApp[];
    try {
      suggestionSearch = await appController.getManyAppsByName(appSearch);
      const filteredSearch = await appHelper.checkAddedAppsByIds(existingAppIds, suggestionSearch);
      return setSuggestions(filteredSearch);
    } catch (error: any) {
      return enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  const handleSaveApp = (appData: IApp) => {
    saveApp(appData);
    setAppSearch('');
  };

  useEffect(() => {
    if (appSearch.length > 2) searchLocalApps();
    else setSuggestions(undefined);
  }, [appSearch]);

  return (
    <div>
      <div className="searchBox">
        <InputField name="app" label="App Name" value={appSearch} handleChange={(v) => setAppSearch(v.target.value)} />
      </div>
      <SavableSuggestions
        addApp={addApp}
        inList
        apps={suggestions}
        saveApp={(appData: IApp) => handleSaveApp(appData)}
      />
    </div>
  );
};
