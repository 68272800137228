import React from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';

interface ButtonProps {
  label?: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
  type?: 'primary' | 'danger' | 'warning' | 'plain';
  icon?: any;
}

export const Button = ({ label, onClick, disabled, className, children, type, icon }: ButtonProps) => {
  const buttonStyles = classNames(styles.button, className, {
    [styles.primary]: type === 'primary',
    [styles.danger]: type === 'danger',
    [styles.warning]: type === 'warning',
    [styles.iconButton]: icon,
    [styles.plain]: type === 'plain',
  });
  return (
    <button className={buttonStyles} onClick={onClick} disabled={disabled}>
      {icon}
      {label || children}
    </button>
  );
};
