import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { userController } from 'controllers';
import { errorHelper } from 'helpers';
import { EmailVerification } from 'components/generic';

interface VerifyEmailPageProps {
  setIsLoggedIn: React.Dispatch<React.SetStateAction<any>>;
}

function VerifyEmailPage({ setIsLoggedIn }: VerifyEmailPageProps) {
  const [verificationCode, setVerificationCode] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  async function verifyEmail() {
    try {
      if (verificationCode?.length < 5) throw new errorHelper.CodeError('Please fill in the code', 401);
      await userController.verifyEmail(verificationCode);
      enqueueSnackbar('Email verified', { variant: 'success' });
      setIsLoggedIn(false);
      return navigate('/');
    } catch (error: any) {
      return enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  return (
    <EmailVerification
      verifyEmail={() => verifyEmail()}
      verificationCode={verificationCode}
      setVerificationCode={(c) => setVerificationCode(c)}
    />
  );
}

export default VerifyEmailPage;
