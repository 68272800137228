import React from 'react';
import { useNavigate } from 'react-router-dom';
import { listInterface } from 'interfaces';
import { slugify } from 'utils/slugify';

function ListSearchComponent({
  listData,
  clickable,
  listClicked,
}: {
  listData: listInterface.IList;
  clickable: boolean;
  listClicked?: Function;
}) {
  const navigate = useNavigate();
  async function goToList() {
    if (listClicked) listClicked();
    return navigate(`/list/${slugify(listData.name)}/${listData.uuid}`);
  }

  return (
    <div
      className="appComponent"
      role="button"
      tabIndex={0}
      onKeyDown={() => {
        if (clickable) goToList();
      }}
      onClick={() => {
        if (clickable) goToList();
      }}
      key={listData.uuid}
    >
      <div className="dataBox">
        <div className="textBox">
          <p className="title">{listData.name}</p>
        </div>
      </div>
    </div>
  );
}

export default ListSearchComponent;
