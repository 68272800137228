import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'styles/components/generic/menu.scss';
import { Suggestions } from 'components/search';
import { BsXLg } from 'react-icons/bs';
import { HiMenuAlt4 } from 'react-icons/hi';
import { InnerMenuProps } from './menu';

export const MobileMenu = (props: InnerMenuProps) => {
  const [burger, setBurger] = useState<boolean>(false);
  const { logout, isLoggedIn, getAllApps, count, lists, apps } = props;
  const { search, setSearch, showSearch, setShowSearch } = props;
  return (
    <div className="menu">
      <div className="logoBox">
        <Link className="logo" to="/">
          <span className="logo-text">slocco</span>
        </Link>
      </div>

      <div
        className="searchBox"
        onBlur={(e) => {
          if (e.relatedTarget === null) setShowSearch(false);
        }}
        onFocus={() => setShowSearch(true)}
      >
        {['/login', '/register'].indexOf(window.location.pathname) === -1 && (
          <div className="searchWrap">
            <div className="burger">
              <HiMenuAlt4 className="widget" onClick={() => setBurger(!burger)} />
            </div>
            <div className="burgerContainer" style={burger ? { top: 90, zIndex: 50 } : { top: -500 }}>
              <div className="searchContainer">
                <input
                  className="searchField"
                  value={search}
                  placeholder="Search Apps and Collections"
                  onChange={(v) => setSearch(v.target.value)}
                />
                {search.length > 0 && <BsXLg className="clearIcon" onClick={() => setSearch('')} />}

                {search.length > 2 && (!!apps.length || !!lists.length) && showSearch && (
                  <Suggestions
                    appClicked={() => {
                      setSearch('');
                      setBurger(false);
                    }}
                    lists={lists}
                    apps={apps}
                  />
                )}
              </div>
              {isLoggedIn ? (
                <>
                  <Link onClick={() => setBurger(false)} className="menuText" to="/new-app">
                    Add New App
                  </Link>
                  <Link onClick={() => setBurger(false)} className="menuText" to="/lists/followed">
                    Favourite Lists
                  </Link>
                  <Link onClick={() => setBurger(false)} className="menuText" to="/lists/owned">
                    My Lists
                  </Link>
                  <Link onClick={() => setBurger(false)} className="menuText" to="/subscriptions">
                    Subscriptions
                  </Link>
                  <Link onClick={() => setBurger(false)} className="menuText" to="/profile">
                    Profile
                  </Link>
                  <button
                    type="button"
                    onClick={() => {
                      setBurger(false);
                      logout();
                    }}
                    className="menuText"
                  >
                    Log Out
                  </button>
                </>
              ) : (
                <Link onClick={() => setBurger(false)} className="menuText" to="/login">
                  Log in
                </Link>
              )}
            </div>
          </div>
        )}
        {search.length === 0 && apps.length < count && showSearch && (
          <button type="button" onClick={() => getAllApps()} className="cta">
            Load More
          </button>
        )}
      </div>
    </div>
  );
};
