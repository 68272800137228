import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { listInterface } from 'interfaces';
import { listController } from 'controllers';
import 'styles/pages/myListsPage.scss';
import { ListTable, NewListModal } from 'components/list';
import { IUser } from 'interfaces/userInterface';
import { pageSize } from 'utils/constants';
import { Helmet } from 'react-helmet';

function MyListsPage({ userData }: { userData: IUser }) {
  const [user, setUser] = useState<IUser>();
  const [listData, setListData] = useState<listInterface.IList[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [listTosShow, setListTosShow] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const canLoadMore = total > page * pageSize;
  async function getLists(newListId?: string) {
    try {
      const listFetch = await listController.getListsByUserId(userData.uuid);
      setListData(listFetch.items);
      setTotal(listFetch.total);
      if (newListId) setListTosShow(newListId);
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
    setLoading(false);
  }

  async function getListsPaginated() {
    try {
      const listFetch = await listController.getListsByUserId(userData.uuid, page + 1);
      if (listData) setListData([...listData, ...listFetch.items]);
      setPage(page + 1);
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    if (userData) getLists();
    setUser(userData);
    setLoading(false);
  }, [userData]);

  return (
    <>
      {showModal && (
        <NewListModal hideModal={() => setShowModal(false)} updateLists={(newListId: string) => getLists(newListId)} />
      )}
      <div className="myListsPage">
        <Helmet>
          <title>My Lists - Slocco</title>
        </Helmet>
        {user && listData && !loading && (
          <>
            <h1>My lists</h1>
            <button
              type="button"
              className="cta"
              onClick={() => {
                setShowModal(true);
              }}
            >
              Create List
            </button>
            {/* <BsPlusLg className="addIcon" onClick={() => { setShowModal(true); }} /> */}
            <ListTable openedListId={listTosShow} userData={userData} reloadLists={() => getLists()} lists={listData} />
            {canLoadMore ? (
              <button type="button" className="cta" onClick={() => getListsPaginated()}>
                Load More
              </button>
            ) : (
              false
            )}
          </>
        )}
      </div>
    </>
  );
}

export default MyListsPage;
