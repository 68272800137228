import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSnackbar } from 'notistack';

import 'styles/pages/appPage.scss';
import { appController } from 'controllers';
import { InputField, Modal } from 'components/generic';
import { AppPlatforms } from 'components/apps';
import { appInterface } from 'interfaces';
import { AddSubscription } from 'components/subscriptions/AddSubscription';
import { ISubscription, IUser } from 'interfaces/userInterface';
import { Button } from 'components/generic/Button';
import { Subscription } from 'components/subscriptions/Subscription';
import Chip from 'components/Chip';
import ExpandableText from 'components/ExpandableText/ExpandableText';
import EditAppPage from './EditAppPage';
import { Announcement } from 'components/generic/Announcement';
import DynamicIcon from 'components/SvgRender/SvgRender';

interface AppPageProps {
  isLoggedIn?: boolean;
  userData?: IUser;
}

function AppPage({ isLoggedIn, userData }: AppPageProps) {
  const params: any = useParams();
  const navigate = useNavigate();
  const { uuid }: { uuid: string } = params;
  const { enqueueSnackbar } = useSnackbar();
  const [app, setApp] = useState<appInterface.IApp>();
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reportMessage, setReportMessage] = useState<string>('');
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showSubscriptions, setShowSubscriptions] = useState<boolean>(false);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [totalSubscriptions, setTotalSubscriptions] = useState<{ totalInPreferred: number; currency: string }>();
  const container = useRef(null);

  async function reportApp() {
    if (!app) return enqueueSnackbar('App is not yet loaded', { variant: 'error' });
    if (!reportMessage) return enqueueSnackbar('Message is required', { variant: 'error' });
    try {
      await appController.reportApp({ uuid: app.uuid, message: reportMessage });
      return enqueueSnackbar('Your report has been sent', {
        variant: 'success',
      });
    } catch (error: any) {
      return enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  async function getAppById() {
    let appSearch: {
      app: appInterface.IApp;
      subscriptions: ISubscription[];
      total: { totalInPreferred: number; currency: string };
    };
    try {
      appSearch = await appController.getAppById(uuid);
      if (!appSearch) return navigate('/');
      setApp(appSearch.app);
      setSubscriptions(appSearch.subscriptions);
      setTotalSubscriptions(appSearch.total);
    } catch (error: any) {
      return navigate('/');
    } finally {
      return setIsLoading(false);
    }
  }

  async function addNewPlatform({ newPlatformType, newPlatformData }: appInterface.INewPlatform) {
    try {
      await appController.addNewPlatform({
        appId: uuid,
        newPlatform: { name: newPlatformType, data: newPlatformData },
      });
      getAppById();
      enqueueSnackbar('App Saved', { variant: 'success' });
    } catch (error: any) {
      enqueueSnackbar(!error?.message?.length ? error.message : 'Some error occured while adding a new platform', {
        variant: 'error',
      });
    }
  }

  function changeWidth(element: HTMLElement) {
    const ref = element.parentNode as HTMLElement;
    const { width } = window.getComputedStyle(ref, null);
    element.style.maxWidth = `${Math.floor(parseFloat(width) / 200) * 200}px`;
  }

  useEffect(() => {
    if (!params.uuid) navigate('/');
    else getAppById();
  }, [params.uuid]);

  useEffect(() => {
    if (container.current) {
      changeWidth(container.current);
      window.onresize = () => {
        if (container.current !== null) {
          const temp = container.current as HTMLElement;
          changeWidth(temp);
        }
      };
    }
    return () => {
      window.onresize = null;
    };
  });

  return isLoading || !app ? (
    <h1>Loading...</h1>
  ) : (
    <>
      {app?.announcement && <Announcement type={app.announcement.type} text={app.announcement.text} />}
      <div className="appPage">
        <Helmet>
          <title>{app?.title} - Slocco</title>
        </Helmet>
        {showReportModal && (
          <Modal hideModal={() => setShowReportModal(false)}>
            <p style={{ fontSize: '20px' }}>Report Message</p>
            <InputField
              name="report"
              handleChange={(e) => setReportMessage(e.target.value)}
              placeholder="Report Message"
            />
            <Button
              type="primary"
              onClick={() => {
                reportApp();
                setShowReportModal(false);
              }}
            >
              REPORT
            </Button>
          </Modal>
        )}
        {showEditModal && (
          <Modal hideModal={() => setShowEditModal(false)}>
            <EditAppPage hideModal={setShowEditModal} />
          </Modal>
        )}
        {showAddModal && (
          <div className="addSubscription">
            <Modal hideModal={() => setShowAddModal(false)}>
              <AddSubscription
                reload={getAppById}
                presetApp={app}
                currency={userData?.config?.subscriptions?.preferredCurrency?.id || 'USD'}
                setMode={setShowAddModal}
              />
            </Modal>
          </div>
        )}
        {showSubscriptions && (
          <Modal hideModal={() => setShowSubscriptions(false)}>
            <div className="subscriptionsList">
              {subscriptions?.map((subscription) => (
                <Subscription key={subscription.uuid} subscription={{ ...subscription, app }} />
              ))}
            </div>
          </Modal>
        )}
        <div className="firstAppInfo">
          <div className="actions">
            <div className="actionBox">
              {app?.links.map((link, i) => {
                return (
                  <Button
                    icon={<DynamicIcon height={18} width={18} name={link.type} />}
                    key={i}
                    className={link.type === 'twitter' ? 'linksButton' : ''}
                    onClick={() => (window.location.href = link.link)}
                  >
                    {link.type.charAt(0).toUpperCase() + link.type.slice(1)}
                  </Button>
                );
              })}
              {isLoggedIn && (
                <>
                  <Button type="danger" onClick={() => setShowReportModal(true)}>
                    Report
                  </Button>
                  <Button onClick={() => setShowEditModal(true)}>Edit</Button>
                </>
              )}
            </div>
          </div>
          <div className="appLogoTitle">
            <img className="appImage" referrerPolicy="no-referrer" src={app?.imageUrl} alt={app?.title} />
            <div>
              <h1 className="appTitleMobile">{app?.title}</h1>
              <span className="developerMobile">By: {app?.sellerName}</span>
            </div>
          </div>
          <div className="firstSection">
            <div>
              <h1 className="appTitle">{app?.title}</h1>
              <span className="developer">By: {app?.sellerName}</span>
            </div>
            <div>
              <span className="downloadText">Platforms:</span>
              <div className="appStatContainer">
                {app && (
                  <AppPlatforms
                    isLoggedIn={isLoggedIn}
                    appName={app.title}
                    platforms={app.platforms}
                    addNewPlatform={({ newPlatformType, newPlatformData }: appInterface.INewPlatform) =>
                      addNewPlatform({ newPlatformType, newPlatformData })
                    }
                  />
                )}
              </div>
            </div>
            <div>
              <span className="downloadText">Categories/Tags:</span>
              <div className="categories">
                {app?.categories.map((e) => {
                  return (
                    <Chip
                      key={e}
                      type="category"
                      onClick={() => navigate(`/apps?category=${encodeURIComponent(e)}`)}
                      style={{ marginBottom: '5px' }}
                    >
                      {e}
                    </Chip>
                  );
                })}
                {app?.tags?.map((e) => (
                  <Chip
                    key={e}
                    type="tag"
                    onClick={() => navigate(`/apps?tag=${encodeURIComponent(e)}`)}
                    style={{ marginBottom: '5px' }}
                  >
                    {e}
                  </Chip>
                ))}
              </div>
            </div>
          </div>
        </div>
        {isLoggedIn && (
          <div className="subscriptions">
            <Button
              disabled={subscriptions?.length ? false : true}
              onClick={() => navigate(`/subscriptions?subApp=${app.title.split(' ')[0]}`)}
              type="primary"
            >
              SUBSCRIPTION |{' '}
              {totalSubscriptions?.totalInPreferred.toLocaleString('en-US', {
                style: 'currency',
                currency: totalSubscriptions?.currency || 'USD',
                maximumFractionDigits: 2,
              })}
            </Button>
            <Button onClick={() => setShowAddModal(!showAddModal)}>Add subscription</Button>
          </div>
        )}

        <div>
          {!!app?.screenshots?.length && (
            <div className="screenshotBox">
              {app?.screenshots.map((screenshotUrl) => (
                <img
                  key={screenshotUrl}
                  className="screenshot"
                  referrerPolicy="no-referrer"
                  src={screenshotUrl}
                  alt={app?.title}
                />
              ))}
            </div>
          )}
        </div>
        {!!app?.description && (
          <div className="descriptionBox">
            <ExpandableText text={app?.description} title="Description: " />
          </div>
        )}
        {app?.customAttributes?.map((attribute) =>
          attribute.name === 'YouTube Video' ? (
            <div key={attribute.id} className="descriptionBox">
              <iframe
                width="450"
                height="250"
                style={{ maxWidth: '100%' }}
                src={attribute.value}
                title="YouTube video player"
                // eslint-disable-next-line max-len
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          ) : (
            <div key={attribute.id} className="descriptionBox">
              <ExpandableText text={attribute.value} title={attribute.name} />
            </div>
          )
        )}
      </div>
    </>
  );
}

export default AppPage;
