/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { listInterface } from 'interfaces';
import { InputField, CheckboxField, Modal } from 'components/generic';
import 'styles/components/list/newListModal.scss';
import { listController } from 'controllers';
import { useNavigate } from 'react-router-dom';
import { slugify } from 'utils/slugify';

function NewListModal({ hideModal, updateLists }: { hideModal: Function; updateLists: Function }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [listData, setListData] = useState<listInterface.INewList>({
    isPublic: false,
    name: '',
    description: '',
  });

  async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const regex = /^[a-z0-9 .,_\\/-]+$/i;
    if (regex.test(e.target.value)) {
      setListData({
        ...listData,
        [e.target.name]: e.target.value,
      });
    }
  }

  async function createList() {
    try {
      let newList: any;
      if (listData)
        newList = await listController.createList({
          ...listData,
          name: listData.name?.trim(),
          description: listData.description?.trim(),
        });
      enqueueSnackbar('List created', { variant: 'success' });
      hideModal();
      updateLists(newList?.uuid).then(() => navigate(`/list/${slugify(newList?.name)}/${newList?.uuid}`));
      return;
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  return (
    <Modal hideModal={() => hideModal()}>
      <div className="newListModal">
        <h1 className="title">New List</h1>
        <div className="inputWrapper">
          <InputField
            name="name"
            label="List Name"
            maxLength={70}
            value={listData.name}
            handleChange={(e) => handleChange(e)}
          />
          <InputField
            name="description"
            label="Description"
            maxLength={140}
            value={listData.description}
            handleChange={(e) => handleChange(e)}
          />
          <CheckboxField
            name="isPublic"
            label="Public"
            isChecked={listData?.isPublic}
            handleChange={() => setListData({ ...listData, isPublic: !listData?.isPublic })}
          />
        </div>
        <button type="button" onClick={createList} className="cta">
          Submit
        </button>
      </div>
    </Modal>
  );
}

export default NewListModal;
