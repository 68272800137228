import React from 'react';
import { useNavigate } from 'react-router-dom';
import { appInterface } from 'interfaces';
import { slugify } from 'utils/slugify';

function AppSearchComponent({
  appData,
  clickable,
  appClicked,
}: {
  appData: appInterface.IExternalParsedApp | appInterface.IApp;
  clickable: boolean;
  appClicked?: Function;
}) {
  const navigate = useNavigate();
  async function goToApp() {
    if (appClicked) appClicked();
    return navigate(`/app/${slugify(appData.title)}/${appData.uuid}`);
  }

  return (
    <div
      className="appComponent"
      role="button"
      tabIndex={0}
      onKeyDown={() => {
        if (clickable) goToApp();
      }}
      onClick={() => {
        if (clickable) goToApp();
      }}
      key={appData.uuid}
    >
      <div className="dataBox">
        <img className="appImage" height="35" referrerPolicy="no-referrer" src={appData.imageUrl} alt={appData.title} />
        <div className="textBox">
          <p className="title">{appData.title}</p>
        </div>
      </div>
    </div>
  );
}

export default AppSearchComponent;
