import React, { useState } from 'react';
import { INewSubscription, SubscriptionPlan } from 'interfaces/userInterface';
import { subscriptionsController } from 'controllers';
import { AppSearch } from 'components/apps/AppSearch';
import { useSnackbar } from 'notistack';
import { IApp } from 'interfaces/appInterface';
import { InputField, SelectorField } from 'components/generic';
import { LabelValue } from 'interfaces/generic';
import { AppList } from 'components/apps';
import styles from './AddSubscription.module.scss';
import { currencyOptions } from 'utils/constants';
import { Button } from 'components/generic/Button';

interface AddSubscriptionProps {
  reload?: () => void;
  presetApp?: IApp;
  setMode: (mode: boolean) => void;
  subscription?: INewSubscription & { app: IApp };
  currency?: string;
}

const billingIntervals = {
  month: { value: 'month', label: 'Month' },
  year: { value: 'year', label: 'Year' },
};

export const AddSubscription = ({
  reload,
  setMode,
  presetApp,
  subscription,
  currency = 'USD',
}: AddSubscriptionProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedApp, setSelectedApp] = useState<IApp | undefined>(presetApp || subscription?.app);

  const [subscriptionData, setSubscriptionData] = useState<INewSubscription>({
    appId: presetApp?.uuid || subscription?.app?.uuid || subscription?.appId || '',
    cost: subscription?.cost || 0,
    currency: subscription?.currency || { value: currency, label: currency },
    interval: subscription?.interval || 'month',
    plan: subscription?.plan || SubscriptionPlan.Paid,
    note: subscription?.note || '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setSubscriptionData((s) => ({ ...s, [e.target.name]: e.target.value }));
  };

  const addSubscription = async () => {
    try {
      await subscriptionsController.addSubscription({
        ...subscriptionData,
        currency: subscriptionData.currency?.value,
      });

      if (reload) reload();
      enqueueSnackbar('Subscription added', { variant: 'success' });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    setMode(false);
  };

  const handleSetSubscriptionApp = (app?: IApp) => {
    setSubscriptionData((s) => ({ ...s, appId: app?.uuid || '' }));
    setSelectedApp(app);
  };

  return (
    <div className={styles.addSubscription}>
      {presetApp ? null : selectedApp ? (
        <AppList deleteApp={() => handleSetSubscriptionApp(undefined)} owned apps={selectedApp ? [selectedApp] : []} />
      ) : (
        <AppSearch addApp existingAppIds={[]} saveApp={handleSetSubscriptionApp} />
      )}
      {subscriptionData.plan === SubscriptionPlan.Paid && (
        <div className={styles.priceBox}>
          <InputField name="cost" placeholder="Amount" handleChange={handleChange} />
          <SelectorField
            className={styles.currencySelector}
            name="currency"
            options={currencyOptions}
            value={subscriptionData.currency}
            handleChange={(e: LabelValue) => setSubscriptionData((s) => ({ ...s, currency: e }))}
          />
        </div>
      )}
      <SelectorField
        name="billing-frequency"
        label="Billing Frequency"
        options={Object.values(billingIntervals)}
        value={billingIntervals[subscriptionData.interval]}
        handleChange={(e: LabelValue) =>
          setSubscriptionData({ ...subscriptionData, interval: e.value as 'month' | 'year' })
        }
      />
      <InputField
        name="Note"
        placeholder="Note"
        value={subscriptionData.note}
        handleChange={(e: any) => setSubscriptionData((s) => ({ ...s, note: e.target.value }))}
      />
      <Button onClick={addSubscription} type="primary">
        SUBMIT
      </Button>
    </div>
  );
};
