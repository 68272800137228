import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { withRouter } from './with-router';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { logout } from 'controllers/userController';
import { IJwtDecode } from 'interfaces/userInterface';

const AuthVerify = (props: any) => {
  const navigate = useNavigate();
  const location = props.router.location;
  useEffect(() => {
    const token = Cookies.get('accessToken');
    if (token) {
      const decodedJwt: IJwtDecode = jwtDecode(token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        logout();
        navigate('/login', { state: { redirect: true } });
        props.setIsLoggedIn(false);
      }
    }
  }, [location]);
  return <div></div>;
};
export default withRouter(AuthVerify);
