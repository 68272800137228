import React, { useEffect, useState } from 'react';
import 'styles/components/generic/menu.scss';
import { MobileMenu } from './MobileMenu';
import { DesktopMenu } from './DesktopMenu';
import { appInterface, listInterface } from 'interfaces';
import { appController, listController, userController } from 'controllers';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { NodeEnv } from 'interfaces/generic';

interface MenuProps {
  isLoggedIn?: boolean;
  setIsLoggedIn: Function;
}

export interface InnerMenuProps {
  isLoggedIn?: boolean;
  logout: () => void;
  getAllApps: () => void;
  count: number;
  lists: listInterface.IList[];
  apps: appInterface.IApp[];
  search: string;
  setSearch: (value: string) => void;
  showSearch: boolean;
  setShowSearch: (value: boolean) => void;
}

function Menu(props: MenuProps) {
  const { setIsLoggedIn } = props;
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [apps, setApps] = useState<appInterface.IApp[]>([]);
  const [lists, setLists] = useState<listInterface.IList[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const nodeEnv: NodeEnv | undefined = process.env.REACT_APP_NODE_ENV as NodeEnv;

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  async function logout() {
    await userController.logout();
    setIsLoggedIn(false);
    Cookies.remove('refreshToken', {
      path: '/',
      domain: nodeEnv === 'local' ? undefined : '.slocco.com',
    });
    Cookies.remove('accessToken', {
      path: '/',
      domain: nodeEnv === 'local' ? undefined : '.slocco.com',
    });
    navigate('/login');
  }

  async function getAllApps() {
    try {
      const appsFetch: { apps: appInterface.IApp[]; count: number } = await appController.getAllApps({ page });
      setApps(apps.concat(appsFetch.apps));
      setCount(appsFetch.count);
      setPage(page + 1);
    } catch (error: any) {
      console.log(error);
    }
  }

  async function findApps() {
    let appsFetch: appInterface.IApp[];
    try {
      appsFetch = await appController.getManyAppsByName(search);
      return setApps(appsFetch);
    } catch (error: any) {
      return enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  async function findLists() {
    let listsFetch: listInterface.IList[];
    try {
      listsFetch = await listController.getManyListsByName(search);
      return setLists(listsFetch);
    } catch (error: any) {
      return enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    if (search.length > 2) {
      findApps();
      findLists();
    } else if (search.length <= 2) {
      setApps([]);
      setLists([]);
    }
  }, [search]);

  if (width < 1248)
    return (
      <MobileMenu
        {...props}
        logout={logout}
        getAllApps={getAllApps}
        count={count}
        lists={lists}
        apps={apps}
        search={search}
        setSearch={setSearch}
        showSearch={showSearch}
        setShowSearch={setShowSearch}
      />
    );
  return (
    <DesktopMenu
      {...props}
      logout={logout}
      getAllApps={getAllApps}
      count={count}
      lists={lists}
      apps={apps}
      search={search}
      setSearch={setSearch}
      showSearch={showSearch}
      setShowSearch={setShowSearch}
    />
  );
}

export default Menu;
