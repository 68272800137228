import { getData } from 'country-list';

export const categoryOptions = [
  { value: 'Books', label: 'Books' },
  { value: 'Business', label: 'Business' },
  { value: 'Education', label: 'Education' },
  { value: 'Entertainment', label: 'Entertainment' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Food & Drink', label: 'Food & Drink' },
  { value: 'Games', label: 'Games' },
  { value: 'Health & Fitness', label: 'Health & Fitness' },
  { value: 'Lifestyle', label: 'Lifestyle' },
  { value: 'Magazines & Newspapers', label: 'Magazines & Newspapers' },
  { value: 'Medical', label: 'Medical' },
  { value: 'Music', label: 'Music' },
  { value: 'Navigation', label: 'Navigation' },
  { value: 'News', label: 'News' },
  { value: 'Photo & Video', label: 'Photo & Video' },
  { value: 'Productivity', label: 'Productivity' },
  { value: 'Reference', label: 'Reference' },
  { value: 'Shopping', label: 'Shopping' },
  { value: 'Social Networking', label: 'Social Networking' },
  { value: 'Sports', label: 'Sports' },
  { value: 'Stickers', label: 'Stickers' },
  { value: 'Travel', label: 'Travel' },
  { value: 'Utilities', label: 'Utilities' },
  { value: 'Weather', label: 'Weather' },
];

export const platformOptions = [
  { value: 'itunes', label: 'iOS' },
  { value: 'android', label: 'Android' },
  { value: 'windows', label: 'Windows' },
  { value: 'mac', label: 'macOS' },
  { value: 'linux', label: 'Linux' },
  { value: 'web', label: 'Web' },
];

export const platformOptionsKeys = {
  itunes: 'iOS',
  android: 'Android',
  windows: 'Windows',
  mac: 'macOS',
  linux: 'Linux',
  web: 'Web',
};

export const pageSize = 10;

export const currencyOptions = [
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
  { value: 'GBP', label: 'GBP' },
  { value: 'CAD', label: 'CAD' },
];

export const emailFrequencies = [
  { value: '1', label: 'Weekly' },
  { value: '2', label: 'Bi-Weekly' },
  { value: '3', label: 'Monthly' },
];

export const weekDays = [
  { value: '0', label: 'Sunday' },
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tuesday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' },
];

export const monthDays = [...Array.from({ length: 31 }, (_, i) => ({ value: `${i + 1}`, label: `${i + 1}` }))];

export const countryOptions = getData().map((country) => ({ value: country.code, label: country.name }));
