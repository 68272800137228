import React from 'react';
import { Svg } from '../../assets/svg';

const DynamicIcon = ({
  name,
  height = 'auto',
  width = 'auto',
}: {
  name: string;
  height: number | string;
  width: number | string;
}) => {
  const IconComponent = Svg[name];

  if (!IconComponent) {
    console.warn(`Icon ${name} not found`);
    return null;
  }

  return <img src={IconComponent} height={height} width={width} />;
};

export default DynamicIcon;
