import React, { useState } from 'react';
import styles from './expandableText.module.scss';
import classNames from 'classnames';

function ExpandableText({ title, text, className }: any) {
  const [expand, setExpand] = useState(text.length > 400 ? false : true);
  const expandableTextStyles = classNames(styles.expandableText, className);
  return (
    <div className={expandableTextStyles}>
      <p className={styles.expandableText_title}>{title}</p>
      <p className={styles.expandableText_text}>{expand ? text : `${text.slice(0, 400)}...`}</p>
      {text.length > 400 && <button onClick={() => setExpand(!expand)}>{!expand ? 'More' : 'Less'}</button>}
    </div>
  );
}

export default ExpandableText;
