import classNames from 'classnames';
import React from 'react';
import 'styles/components/generic/inputField.scss';

interface InputFieldProps {
  name: string;
  label?: string;
  type?: string;
  required?: boolean;
  value?: string | number;
  maxLength?: number;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
}

function InputField({
  name,
  label,
  required,
  type,
  value,
  handleChange,
  disabled,
  maxLength,
  className,
  placeholder,
}: InputFieldProps) {
  return (
    <div className={classNames('inputField', className)}>
      {label && (
        <label htmlFor={name} className="label">
          {label}
        </label>
      )}
      <input
        autoComplete="off"
        disabled={disabled}
        id={name}
        name={name}
        required={required}
        type={type || 'text'}
        className="input"
        maxLength={maxLength}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  );
}

export default InputField;
