import { Button } from 'components/generic/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface NoSearchResultsProps {
  addApp?: boolean;
}

function NoSearchResults({ addApp }: NoSearchResultsProps) {
  const navigate = useNavigate();
  return (
    <div className="appComponent">
      <div className="textBox">
        <p className="title" style={{ lineHeight: '30px' }}>
          Unfortunately, we didn`t find what you are looking for <br />
          You can <Button onClick={() => navigate('/new-app')}>Add new app</Button>
        </p>
        {addApp && <Button label="Add New App" onClick={() => navigate('/new-app')} />}
      </div>
    </div>
  );
}

export default NoSearchResults;
