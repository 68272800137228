import React, { useState, useEffect } from 'react';
import 'styles/components/generic/uploadBox.scss';
import { BsUpload, BsXCircle } from 'react-icons/bs';

function UploadBox({
  name,
  label,
  previewImage,
  setFile,
  removeFile,
  disabled,
}: {
  name: string;
  label?: string;
  previewImage?: { file?: File; name?: string };
  setFile: Function;
  removeFile: Function;
  disabled?: boolean;
}) {
  const [imageSrc, setImageSrc] = useState('');

  async function parseFile() {
    if (previewImage?.file) {
      const reader = new FileReader();
      reader.readAsDataURL(previewImage.file);
      reader.onloadend = () => reader.result && typeof reader.result === 'string' && setImageSrc(reader.result);
    } else {
      setImageSrc('');
    }
  }

  useEffect(() => {
    parseFile();
  }, [previewImage]);

  return (
    <div className="uploadBox">
      {label && (
        <label htmlFor={name} className="label">
          {label}
        </label>
      )}
      <div className="uploadBoxInner">
        {imageSrc && <img src={imageSrc} referrerPolicy="no-referrer" className="imagePreview" alt="Icon Preview" />}
        {previewImage?.name && <span className="preLabel">{previewImage.name}</span>}
        {!previewImage?.file ? (
          <label className="fileUploader" htmlFor="fileUpload">
            <BsUpload className="uploadIcon" />
            <span className="preLabel">Please select a file</span>
          </label>
        ) : (
          <BsXCircle className="removeIcon" onClick={() => removeFile()} />
        )}
        <input
          disabled={disabled}
          id="fileUpload"
          name={name}
          type="file"
          className="fileButton"
          onChange={(e) => setFile(e)}
        />
      </div>
    </div>
  );
}

export default UploadBox;
