import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

import { ListTable } from 'components/list';
import { listController, userController } from 'controllers';
import { listInterface, pageInterface, userInterface } from 'interfaces';
import { IUser } from 'interfaces/userInterface';
import 'styles/pages/feedPage.scss';
import { Link } from 'react-router-dom';
import { UserPreview } from 'components/users';
import { pageSize } from 'utils/constants';
import { Helmet } from 'react-helmet';

interface FeedPageProps {
  userData?: IUser;
  reloadUser?: Function;
}

function FeedPage({ userData, reloadUser }: FeedPageProps) {
  const [isLoading, setLoading] = useState(true);
  const [listData, setListData] = useState<listInterface.INewsFeedList[]>();
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [followedUsers, setFollowedUsers] = useState<userInterface.IUserPublic[]>();
  const [followedLists] = useState<listInterface.IList[]>();
  const { enqueueSnackbar } = useSnackbar();

  const canLoadMore = total > page * pageSize;

  // async function getPersonalFeed() {
  //   try {
  //     const listSearch = await listController.getPersonalFeed();
  //     if (userData && reloadUser) await reloadUser();
  //     setListData(listSearch.items);
  //     setTotal(listSearch.total);
  //     setFollowedLists(
  //       listSearch.items?.filter((list) =>
  //         userData?.followedLists?.find(
  //           (followedList) => followedList.id === list.uuid
  //         )
  //           ? { ...list, isFollowed: true }
  //           : null
  //       )
  //     );
  //   } catch (error: any) {
  //     enqueueSnackbar(error.message, {
  //       variant: 'error',
  //     });
  //   }
  //   setLoading(false);
  // }

  async function getLists() {
    let listSearch: pageInterface.IPage<listInterface.INewsFeedList>;
    try {
      listSearch = await listController.getNewsFeed({
        page: page + 1,
      });
      setTotal(listSearch.total);
      if (listData) setListData([...listData, ...listSearch.items]);
      else setListData(listSearch.items);
      setPage(page + 1);
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
    setLoading(false);
  }

  async function findUsers() {
    try {
      let usersFetch;
      const followedUserIds = userData?.followedUsers?.map((followedUser) => followedUser.id);
      if (followedUserIds && followedUserIds?.length)
        usersFetch = await userController.getProtectedUsersByIds({
          uuidList: followedUserIds,
        });
      return setFollowedUsers(usersFetch);
    } catch (error: any) {
      return enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    // getLists();
    findUsers();
  }, [userData]);

  useEffect(() => {
    getLists();
  }, []);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  return (
    <>
      <Helmet>
        <title>Feed - Slocco</title>
      </Helmet>
      <h1>Discover Apps. Manage Subscriptions.</h1>
      <br />
      <h2>Featured Apps and Lists</h2>
      <div className="mainBox">
        <div className="publicLists">
          {/* <h3 className="title">Public Lists</h3> */}
          {listData ? (
            <>
              <ListTable
                allowFollowing={!!userData}
                userData={userData}
                vertical
                reloadUser={reloadUser ? () => reloadUser() : undefined}
                reloadLists={() => getLists()}
                newsFeed={listData.filter((list) => !!list.apps.length)}
              />
              {canLoadMore ? (
                <button type="button" className="cta" onClick={() => getLists()}>
                  Load More
                </button>
              ) : null}
            </>
          ) : null}
        </div>
        {userData && (!!followedLists?.length || !!followedUsers?.length) && (
          <div className="publicLists">
            <h3 className="title">Favourite Lists & Users</h3>
            {listData ? (
              <ListTable
                allowFollowing={!!userData}
                userData={userData}
                vertical
                reloadUser={reloadUser ? () => reloadUser() : undefined}
                reloadLists={() => getLists()}
                lists={followedLists}
              />
            ) : null}
            <div className="userBox">
              {followedUsers?.map((user) => (
                <div key={user.uuid} className="userWrapper">
                  <Link to={`/users/${user.uuid}`}>
                    <UserPreview userData={user} />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default FeedPage;
