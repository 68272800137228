/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import 'styles/pages/searchPage.scss';
import { useSnackbar } from 'notistack';
import { appController } from 'controllers';
import { Suggestions } from 'components/search';
import { appInterface } from 'interfaces';

function SearchPage() {
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState<appInterface.IApp[]>();
  const { enqueueSnackbar } = useSnackbar();

  async function findApp() {
    let suggestionSearch: appInterface.IApp[];
    try {
      suggestionSearch = await appController.getManyAppsByName(search);
      return setSuggestions(suggestionSearch);
    } catch (error: any) {
      return enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    if (search.length > 2) findApp();
    else setSuggestions(undefined);
  }, [search]);

  return (
    <div className="search">
      <h1 className="title">Slocco</h1>
      <input className="searchField" value={search} onChange={(v) => setSearch(v.target.value)} />
      <Suggestions apps={suggestions} />
    </div>
  );
}

export default SearchPage;
