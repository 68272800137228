/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import 'styles/components/apps/appPlatforms.scss';
import { BsXLg } from 'react-icons/bs';
import { appInterface } from 'interfaces';
import { NewPlatformPopup } from 'components/apps';
import { platformOptionsKeys } from 'utils/constants';
import { Platform, PlatformSelector } from 'interfaces/appInterface';
import Add from 'assets/icons/Add';
import { Button } from 'components/generic/Button';
import DynamicIcon from 'components/SvgRender/SvgRender';

interface AppPlatformsProps {
  label?: string;
  platforms?: appInterface.IPlatforms;
  appName?: string;
  addNewPlatform: Function;
  removePlatform?: Function;
  onAdd?: (
    platform?: appInterface.INewPlatform,
    app?: appInterface.IExternalParsedApp,
    country?: { value: string; label: string }
  ) => void;
  isLoggedIn?: boolean;
}

function AppPlatforms({
  label,
  platforms,
  appName,
  addNewPlatform,
  removePlatform,
  onAdd,
  isLoggedIn,
}: AppPlatformsProps) {
  const [showModal, setShowModal] = useState(false);
  const [showPlatformSuggest, setShowPlatformSuggest] = useState(false);
  const [preSelectedPlatform, setPreSelectedPlatform] = useState<PlatformSelector>();

  useEffect(() => {
    if (!!platforms?.itunes && !platforms?.android) {
      setShowPlatformSuggest(true);
      setPreSelectedPlatform({
        value: Platform.android,
        label: platformOptionsKeys.android,
      });
    } else if (!!platforms?.android && !platforms?.itunes) {
      setShowPlatformSuggest(true);
      setPreSelectedPlatform({
        value: Platform.itunes,
        label: platformOptionsKeys.itunes,
      });
    } else setShowPlatformSuggest(false);
  }, [platforms?.android, platforms?.itunes]);

  let platformKeys: appInterface.Platform[] | 'requests'[] = [];
  if (platforms) platformKeys = Object.keys(platforms) as appInterface.Platform[] | 'requests'[];
  return (
    <>
      {isLoggedIn && showModal && (
        <NewPlatformPopup
          preSelectedPlatform={preSelectedPlatform}
          appName={appName}
          hideModal={() => setShowModal(false)}
          platforms={platforms}
          addNewPlatform={async ({ newPlatformType, newPlatformData }: appInterface.INewPlatform) =>
            await addNewPlatform({ newPlatformType, newPlatformData })
          }
          onAdd={onAdd}
        />
      )}
      <div className="appPlatforms">
        {label && <h3>{label}</h3>}
        <div className="appPlatformsWrapper">
          {!!platformKeys.length &&
            platforms &&
            platformKeys.map(
              (platformKey: appInterface.Platform | 'requests' | '_id') =>
                platformKey !== 'requests' &&
                platformKey !== '_id' &&
                platforms[platformKey]?.url && (
                  <div className="appPlatformBoxWrapper">
                    <a href={platforms[platformKey]?.url} target="_blank" rel="noreferrer" key={platformKey}>
                      <div className="appPlatformBox">
                        <Button icon={<DynamicIcon height={18} width={18} name={platformOptionsKeys[platformKey]} />}>
                          <span>{platformOptionsKeys[platformKey]}</span>
                        </Button>
                      </div>
                    </a>
                    {removePlatform && (
                      <BsXLg
                        onClick={() => {
                          removePlatform(platformKey);
                        }}
                        className="platformDeleteIcon"
                      />
                    )}
                  </div>
                )
            )}
          {isLoggedIn && platformKeys.map((key: string) => key !== 'requests' && key !== '_id').length <= 6 && (
            <>
              <Button type="plain" onClick={() => setShowModal(true)}>
                <Add size={30} />
              </Button>
              {showPlatformSuggest && (
                <span>Consider adding another mobile platform by clicking on the plus icon above</span>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AppPlatforms;
