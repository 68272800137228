import React, { useState } from 'react';
import { EmailFrequency, ISubscriptionTotal } from 'interfaces/userInterface';
import styles from './SubscriptionsHeader.module.scss';
import { SelectorField } from 'components/generic';
import { LabelValue } from 'interfaces/generic';
import { countryOptions, currencyOptions, emailFrequencies, monthDays, weekDays } from 'utils/constants';
import { IUser } from 'interfaces/userInterface';
import { useSnackbar } from 'notistack';
import { repository } from 'services';
import { getEmailToastMessage } from 'helpers/emailToastHelper';

interface SubscriptionsHeaderProps {
  totals: ISubscriptionTotal[];
  total?: string;
  userData: IUser;
  reloadUser: () => void;
}

export const SubscriptionsHeader = ({ totals, total, userData, reloadUser }: SubscriptionsHeaderProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [userUpdateData, setUserUpdateData] = useState(userData);
  const [selectedDay, setSelectedDay] = useState<LabelValue>(
    userData?.config?.subscriptions?.emailFrequency?.sentTime?.dayOfWeek
      ? {
          value: userData?.config?.subscriptions?.emailFrequency?.sentTime?.dayOfWeek || '1',
          label: weekDays[+userData?.config?.subscriptions?.emailFrequency?.sentTime?.dayOfWeek - 1].label || 'Monday',
        }
      : {
          value: userData?.config?.subscriptions?.emailFrequency?.sentTime?.dayOfMonth || '1',
          label: userData?.config?.subscriptions?.emailFrequency?.sentTime?.dayOfMonth || '1',
        }
  );
  const [selectedEmailFrequency, setEmailFrequency] = useState(
    userUpdateData.config?.subscriptions?.emailFrequency?.id || '1'
  );
  const [selectedCountry, setSelectedCountry] = useState(userUpdateData.config?.country?.id || 'US');
  const [selectedCurrency, setSelectedCurrency] = useState(
    userUpdateData.config?.subscriptions?.preferredCurrency?.id || 'USD'
  );

  async function updateUser(user: IUser) {
    try {
      setUserUpdateData((prev) => ({ ...prev, ...user }));
      await repository.updateUser({ userUpdateData: { config: user.config } });
      enqueueSnackbar(getEmailToastMessage(user.config?.subscriptions), { variant: 'success' });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  const handleEmailFrequency = (e: LabelValue) => {
    const isWeekly = e.value === EmailFrequency.Weekly;
    const isMonthly = e.value === EmailFrequency.Monthly;
    const update = {
      ...userUpdateData,
      config: {
        ...userUpdateData.config,
        subscriptions: {
          ...userUpdateData.config?.subscriptions,
          emailFrequency: {
            id: e.value,
            name: e.label,
            sentTime: {
              dayOfWeek: e.value === EmailFrequency.Weekly ? '1' : '',
              dayOfMonth: e.value === EmailFrequency.Monthly ? '1' : '',
            },
          },
        },
      },
    };
    if (isWeekly) setSelectedDay({ value: '1', label: 'Monday' });
    if (isMonthly) setSelectedDay({ value: '1', label: '1' });
    setEmailFrequency(e.value);
    updateUser(update);
  };

  const handleDay = (e: LabelValue) => {
    const update = {
      ...userUpdateData,
      config: {
        ...userUpdateData.config,
        subscriptions: {
          ...userUpdateData.config?.subscriptions,
          emailFrequency: {
            id: selectedEmailFrequency,
            name: emailFrequencies.find((f) => f.value === selectedEmailFrequency)?.label || '',
            sentTime: {
              dayOfWeek: +e.label ? '' : e.value,
              dayOfMonth: +e.label ? e.value : '',
            },
          },
        },
      },
    };
    setSelectedDay(e);
    updateUser(update);
  };

  const handleCountry = (e: LabelValue) => {
    const update = {
      ...userUpdateData,
      config: {
        ...userUpdateData.config,
        country: {
          id: e.value,
          name: e.label,
        },
      },
    };
    setSelectedCountry(e.value);
    updateUser(update);
  };

  const handleCurrency = async (e: LabelValue) => {
    const update: IUser = {
      ...userUpdateData,
      config: {
        ...userUpdateData.config,
        subscriptions: {
          ...userUpdateData.config?.subscriptions,
          preferredCurrency: {
            id: e.value,
            name: e.label,
          },
        },
      },
    };
    setSelectedCurrency(e.value);
    updateUser(update).then(() => reloadUser());
  };

  return (
    <div>
      <span className={styles.currency}>
        <SelectorField
          name="currency"
          label="Default Currency"
          options={currencyOptions}
          value={currencyOptions.find((c) => c.value === selectedCurrency)}
          handleChange={(e: LabelValue) => {
            handleCurrency(e);
          }}
        />
        <div>
          <SelectorField
            name="frequency"
            label="Email Frequency"
            options={emailFrequencies}
            value={emailFrequencies.find((f) => f.value === selectedEmailFrequency)}
            handleChange={handleEmailFrequency}
          />
          {selectedEmailFrequency === '1' ? (
            <SelectorField
              name="weekday"
              label="Week Day"
              options={weekDays}
              value={weekDays.find((d) => d.value === selectedDay.value)}
              handleChange={handleDay}
            />
          ) : selectedEmailFrequency === '3' ? (
            <SelectorField
              name="days"
              label="Month Day"
              options={monthDays}
              value={monthDays.find((d) => d.value === selectedDay.value)}
              handleChange={(e: any) => {
                handleDay(e);
              }}
            />
          ) : (
            <div></div>
          )}
        </div>
        <SelectorField
          name="country"
          label="Country"
          options={countryOptions}
          value={countryOptions.find((f) => f.value === selectedCountry)}
          handleChange={handleCountry}
        />
      </span>

      <div className={styles.subscriptionsHeader}>
        {totals.map((t) => (
          <div key={t.currency} className={styles.total}>
            <span className={styles.totalValue}>
              {t.cost.toLocaleString('en-US', {
                style: 'currency',
                currency: t.currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        ))}
        {!!totals.length && <hr />}
        {total && (
          <div key="summed" className={styles.total}>
            Total (<span className={styles.totalCurrency}>{selectedCurrency}</span>)
            <span className={styles.spacer}>≈</span>
            <span className={styles.totalValue}>{total}</span>
          </div>
        )}
      </div>
    </div>
  );
};
