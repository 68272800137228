/* eslint-disable max-len */
import React from 'react';

export function Logo({ width = 150, height = 26 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 150 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6472 9.10951C11.2721 8.61404 10.7375 8.20432 10.0436 7.88036C9.36839 7.53734 8.68382 7.36583 7.98986 7.36583C7.57724 7.36583 7.16462 7.423 6.752 7.53734C6.33937 7.63263 5.96426 7.79461 5.62666 8.02329C5.28906 8.23291 5.00773 8.51876 4.78266 8.88083C4.57635 9.22385 4.4732 9.6431 4.4732 10.1386C4.4732 10.5959 4.56697 10.9866 4.75453 11.3105C4.94209 11.6345 5.19529 11.9108 5.51413 12.1395C5.85173 12.3682 6.25497 12.5683 6.72386 12.7398C7.19275 12.9113 7.70853 13.0828 8.27119 13.2543C8.90888 13.4639 9.56533 13.7021 10.2405 13.9689C10.9345 14.2357 11.5628 14.5883 12.1255 15.0266C12.7069 15.4649 13.1758 16.0175 13.5321 16.6845C13.9072 17.3515 14.0948 18.1804 14.0948 19.1714C14.0948 20.2576 13.8979 21.2104 13.504 22.0299C13.1101 22.8302 12.585 23.4972 11.9285 24.0308C11.2721 24.5644 10.5031 24.9646 9.62159 25.2314C8.74008 25.4982 7.81168 25.6316 6.83639 25.6316C5.54226 25.6316 4.27626 25.3934 3.0384 24.9169C1.80053 24.4215 0.787733 23.6973 0 22.7445L2.532 20.3434C3.01964 21.0294 3.66671 21.5725 4.4732 21.9727C5.29844 22.3729 6.10493 22.573 6.89266 22.573C7.30528 22.573 7.72728 22.5253 8.15866 22.4301C8.59004 22.3157 8.97453 22.1347 9.31213 21.8869C9.66848 21.6392 9.94981 21.3343 10.1561 20.9722C10.3812 20.5911 10.4937 20.1242 10.4937 19.5716C10.4937 19.038 10.3718 18.5997 10.128 18.2567C9.88417 17.8946 9.55595 17.5897 9.14333 17.342C8.7307 17.0752 8.24306 16.8465 7.68039 16.6559C7.11773 16.4654 6.52693 16.2653 5.908 16.0556C5.30782 15.8651 4.70764 15.6364 4.10746 15.3696C3.50729 15.1028 2.96338 14.7503 2.47573 14.312C2.00684 13.8737 1.62235 13.3401 1.32227 12.7112C1.02218 12.0633 0.872133 11.2629 0.872133 10.3101C0.872133 9.28102 1.07844 8.39489 1.49107 7.65168C1.92244 6.90848 2.47573 6.29867 3.15093 5.82225C3.84489 5.32678 4.62324 4.96471 5.486 4.73603C6.36751 4.48829 7.25839 4.36442 8.15866 4.36442C9.17146 4.36442 10.1936 4.54546 11.2252 4.90754C12.2755 5.26961 13.1851 5.81272 13.9541 6.53687L11.6472 9.10951Z"
        fill="#F5F5F5"
      />
      <path d="M24.6791 4.87895H28.2239V21.9155H36.692V25.117H24.6791V4.87895Z" fill="#F5F5F5" />
      <path
        d="M44.4379 14.9408C44.4379 13.321 44.7005 11.8632 45.2256 10.5673C45.7695 9.25244 46.5104 8.13763 47.4481 7.22291C48.4047 6.30819 49.53 5.6031 50.8241 5.10763C52.137 4.61216 53.5625 4.36442 55.1004 4.36442C56.6571 4.36442 58.0919 4.61216 59.4048 5.10763C60.7177 5.6031 61.8524 6.30819 62.8089 7.22291C63.7655 8.13763 64.5063 9.25244 65.0315 10.5673C65.5754 11.8632 65.8473 13.321 65.8473 14.9408C65.8473 16.5225 65.5754 17.9708 65.0315 19.2857C64.5063 20.5816 63.7655 21.7059 62.8089 22.6587C61.8524 23.5925 60.7177 24.3262 59.4048 24.8598C58.0919 25.3743 56.6571 25.6316 55.1004 25.6316C53.5625 25.6316 52.137 25.3743 50.8241 24.8598C49.53 24.3262 48.4047 23.5925 47.4481 22.6587C46.5104 21.7059 45.7695 20.5816 45.2256 19.2857C44.7005 17.9708 44.4379 16.5225 44.4379 14.9408ZM48.2921 14.9408C48.2921 16.0271 48.4516 17.0275 48.7704 17.9422C49.108 18.8379 49.5769 19.6192 50.1771 20.2862C50.796 20.9532 51.5181 21.4772 52.3433 21.8584C53.1873 22.2204 54.1157 22.4015 55.1285 22.4015C56.1413 22.4015 57.0697 22.2204 57.9137 21.8584C58.7577 21.4772 59.4798 20.9532 60.08 20.2862C60.6989 19.6192 61.1678 18.8379 61.4867 17.9422C61.8243 17.0275 61.9931 16.0271 61.9931 14.9408C61.9931 13.8927 61.8243 12.9208 61.4867 12.0252C61.1678 11.1295 60.7083 10.3482 60.1081 9.68121C59.508 9.01423 58.7859 8.49017 57.9419 8.10904C57.0979 7.72791 56.1601 7.53734 55.1285 7.53734C54.097 7.53734 53.1592 7.72791 52.3152 8.10904C51.49 8.49017 50.7773 9.01423 50.1771 9.68121C49.5769 10.3482 49.108 11.1295 48.7704 12.0252C48.4516 12.9208 48.2921 13.8927 48.2921 14.9408Z"
        fill="#F5F5F5"
      />
      <path
        d="M90.6787 9.62404C90.1536 8.89989 89.469 8.37583 88.625 8.05187C87.781 7.70885 86.9557 7.53734 86.1493 7.53734C85.1177 7.53734 84.1799 7.72791 83.3359 8.10904C82.4919 8.49017 81.7605 9.01423 81.1415 9.68121C80.5414 10.3482 80.0724 11.1295 79.7348 12.0252C79.416 12.9208 79.2566 13.8927 79.2566 14.9408C79.2566 16.0461 79.416 17.0561 79.7348 17.9708C80.0537 18.8855 80.5038 19.6764 81.0853 20.3434C81.6854 20.9913 82.3981 21.4963 83.2234 21.8584C84.0486 22.2204 84.977 22.4015 86.0086 22.4015C87.0777 22.4015 88.0248 22.1919 88.85 21.7726C89.6753 21.3343 90.3411 20.7626 90.8475 20.0575L93.689 22.087C92.8075 23.2114 91.729 24.088 90.4536 24.7169C89.1783 25.3267 87.6872 25.6316 85.9805 25.6316C84.4237 25.6316 82.9889 25.3743 81.676 24.8598C80.3819 24.3262 79.266 23.5925 78.3282 22.6587C77.3904 21.7059 76.6589 20.5816 76.1338 19.2857C75.6086 17.9708 75.3461 16.5225 75.3461 14.9408C75.3461 13.321 75.618 11.8632 76.1619 10.5673C76.7246 9.25244 77.4842 8.13763 78.4407 7.22291C79.416 6.30819 80.5601 5.6031 81.873 5.10763C83.1859 4.61216 84.6113 4.36442 86.1493 4.36442C86.7869 4.36442 87.4528 4.43112 88.1467 4.56452C88.8407 4.67886 89.5065 4.86942 90.1442 5.13621C90.7819 5.38395 91.382 5.69838 91.9447 6.07952C92.5074 6.46065 92.9856 6.918 93.3795 7.45159L90.6787 9.62404Z"
        fill="#F5F5F5"
      />
      <path
        d="M117.301 9.62404C116.776 8.89989 116.091 8.37583 115.247 8.05187C114.403 7.70885 113.578 7.53734 112.771 7.53734C111.74 7.53734 110.802 7.72791 109.958 8.10904C109.114 8.49017 108.383 9.01423 107.764 9.68121C107.164 10.3482 106.695 11.1295 106.357 12.0252C106.038 12.9208 105.879 13.8927 105.879 14.9408C105.879 16.0461 106.038 17.0561 106.357 17.9708C106.676 18.8855 107.126 19.6764 107.708 20.3434C108.308 20.9913 109.02 21.4963 109.846 21.8584C110.671 22.2204 111.599 22.4015 112.631 22.4015C113.7 22.4015 114.647 22.1919 115.472 21.7726C116.298 21.3343 116.963 20.7626 117.47 20.0575L120.311 22.087C119.43 23.2114 118.351 24.088 117.076 24.7169C115.801 25.3267 114.309 25.6316 112.603 25.6316C111.046 25.6316 109.611 25.3743 108.298 24.8598C107.004 24.3262 105.888 23.5925 104.95 22.6587C104.013 21.7059 103.281 20.5816 102.756 19.2857C102.231 17.9708 101.968 16.5225 101.968 14.9408C101.968 13.321 102.24 11.8632 102.784 10.5673C103.347 9.25244 104.106 8.13763 105.063 7.22291C106.038 6.30819 107.182 5.6031 108.495 5.10763C109.808 4.61216 111.234 4.36442 112.771 4.36442C113.409 4.36442 114.075 4.43112 114.769 4.56452C115.463 4.67886 116.129 4.86942 116.766 5.13621C117.404 5.38395 118.004 5.69838 118.567 6.07952C119.13 6.46065 119.608 6.918 120.002 7.45159L117.301 9.62404Z"
        fill="#F5F5F5"
      />
      <path
        d="M128.591 14.9408C128.591 13.321 128.853 11.8632 129.378 10.5673C129.922 9.25244 130.663 8.13763 131.601 7.22291C132.557 6.30819 133.683 5.6031 134.977 5.10763C136.29 4.61216 137.715 4.36442 139.253 4.36442C140.81 4.36442 142.245 4.61216 143.557 5.10763C144.87 5.6031 146.005 6.30819 146.962 7.22291C147.918 8.13763 148.659 9.25244 149.184 10.5673C149.728 11.8632 150 13.321 150 14.9408C150 16.5225 149.728 17.9708 149.184 19.2857C148.659 20.5816 147.918 21.7059 146.962 22.6587C146.005 23.5925 144.87 24.3262 143.557 24.8598C142.245 25.3743 140.81 25.6316 139.253 25.6316C137.715 25.6316 136.29 25.3743 134.977 24.8598C133.683 24.3262 132.557 23.5925 131.601 22.6587C130.663 21.7059 129.922 20.5816 129.378 19.2857C128.853 17.9708 128.591 16.5225 128.591 14.9408ZM132.445 14.9408C132.445 16.0271 132.604 17.0275 132.923 17.9422C133.261 18.8379 133.73 19.6192 134.33 20.2862C134.949 20.9532 135.671 21.4772 136.496 21.8584C137.34 22.2204 138.268 22.4015 139.281 22.4015C140.294 22.4015 141.222 22.2204 142.066 21.8584C142.91 21.4772 143.632 20.9532 144.233 20.2862C144.852 19.6192 145.32 18.8379 145.639 17.9422C145.977 17.0275 146.146 16.0271 146.146 14.9408C146.146 13.8927 145.977 12.9208 145.639 12.0252C145.32 11.1295 144.861 10.3482 144.261 9.68121C143.661 9.01423 142.939 8.49017 142.095 8.10904C141.251 7.72791 140.313 7.53734 139.281 7.53734C138.25 7.53734 137.312 7.72791 136.468 8.10904C135.643 8.49017 134.93 9.01423 134.33 9.68121C133.73 10.3482 133.261 11.1295 132.923 12.0252C132.604 12.9208 132.445 13.8927 132.445 14.9408Z"
        fill="#F5F5F5"
      />
      <path
        d="M8.21654 20.091C10.7792 18.3813 13.3418 16.6716 15.9045 14.9874C18.0037 13.5839 21.4933 11.9508 19.8303 9.04171C18.5762 6.87268 15.0594 8.81205 16.2862 10.9811C16.3407 11.0832 16.3952 11.1852 16.4497 11.2873C16.368 10.9556 16.2589 10.6493 16.1771 10.3176C16.3407 9.96036 16.2316 9.96036 15.8227 10.3687C15.6864 10.4707 15.5228 10.5728 15.3865 10.6493C14.9503 10.93 14.5141 11.2363 14.0779 11.517C13.2055 12.1039 12.3331 12.6908 11.4335 13.2777C9.6887 14.4515 7.91665 15.6254 6.17187 16.7737C4.04541 18.1772 6.06282 21.52 8.21654 20.091Z"
        fill="#373737"
      />
      <path
        d="M31.5803 17.8571C27.9272 17.9592 24.274 18.0358 20.6481 18.1378C18.031 18.2144 18.0037 22.0421 20.6481 21.9655C24.3013 21.8634 28.0996 21.9768 31.6322 21.8388C34.2493 21.7622 34.1975 17.7806 31.5803 17.8571Z"
        fill="#373737"
      />
      <path
        d="M55.9255 15.166C58.7062 10.9556 61.9777 7.10234 65.7399 3.63189C67.621 1.89667 64.7312 -0.808234 62.8501 0.926989C58.8425 4.6271 55.3257 8.76101 52.3814 13.2267C51.0183 15.3446 54.5624 17.284 55.9255 15.166Z"
        fill="#373737"
      />
      <path
        d="M68.548 18.0751C83.4332 17.5647 98.3456 17.0544 113.231 16.544C115.848 16.4674 115.875 12.6142 113.231 12.7163C98.3456 13.2267 83.4332 13.737 68.548 14.2474C65.9308 14.3495 65.9308 18.1771 68.548 18.0751Z"
        fill="#373737"
      />
      <path
        d="M140.013 12.5067C142.5 9.82858 144.838 7.22291 147.728 4.77476C149.773 3.21816 146.856 0.513257 144.838 2.06985C142.066 4.66304 139.239 7.61981 136.496 10.5673C134.969 12.5833 138.513 14.4971 140.013 12.5067Z"
        fill="#373737"
      />
    </svg>
  );
}
