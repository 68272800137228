import { IUser } from 'interfaces/userInterface';

export const getUser = (): IUser | undefined => {
  const localUserData = localStorage.getItem('userData')
    ? JSON.parse(localStorage.getItem('userData') as string)
    : undefined;
  return localUserData;
};

export const setUser = (user: IUser) => {
  localStorage.setItem('userData', JSON.stringify(user));
};
