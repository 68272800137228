import { EmailFrequency, SentTime, SubscriptionsConfig } from 'interfaces/userInterface';
import { weekDays } from 'utils/constants';

const getDayApendix = (day: string) => {
  if (day === '1' || day === '21' || day === '31') return 'st';
  if (day === '2' || day === '22') return 'nd';
  if (day === '3' || day === '23') return 'rd';
  return 'th';
};

export const handleEmailIteration = (sentTime: SentTime | undefined) => {
  if (sentTime?.dayOfMonth) return 'the ' + sentTime.dayOfMonth + getDayApendix(sentTime.dayOfMonth);
  if (sentTime?.dayOfWeek) return weekDays.find((day) => day.value === sentTime.dayOfWeek)?.label;
  return '';
};

export const getEmailToastMessage = (subscriptionsConfig?: SubscriptionsConfig) => {
  if (!subscriptionsConfig || !subscriptionsConfig.emailFrequency) return 'You have not set up email notifications';
  if (subscriptionsConfig.emailFrequency.id === EmailFrequency.Weekly) {
    return `You will receive a weekly email on ${
      weekDays.find((day) => day.value === subscriptionsConfig.emailFrequency?.sentTime.dayOfWeek)?.label
    }`;
  } else if (subscriptionsConfig.emailFrequency.id === EmailFrequency.BiWeekly) {
    return `You will receive a bi-weekly email`;
  } else if (subscriptionsConfig.emailFrequency.id === EmailFrequency.Monthly) {
    const day = subscriptionsConfig.emailFrequency?.sentTime.dayOfMonth;
    return `You will receive a monthly email on the ${day + getDayApendix(day)}`;
  }
};
