import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import 'styles/components/apps/extendedAppList.scss';
import { appInterface } from 'interfaces';
import { Platform } from 'interfaces/appInterface';
import { platformOptionsKeys } from 'utils/constants';
import { slugify } from 'utils/slugify';
import { FiMoreHorizontal } from 'react-icons/fi';
import { Button } from '@mui/material';

function ExtendedAppList({ apps, deleteApp }: { apps: appInterface.IApp[]; deleteApp?: Function }) {
  const [expandDescription, setExpandDescription] = useState<boolean[]>(apps.map(() => false));
  const [isEditing, setIsEditing] = useState<number>();
  const handleExpandDescription = (i: number) => {
    const holder = [...expandDescription];
    holder[i] = !holder[i];
    return setExpandDescription(holder);
  };

  return (
    <div className="extendedAppList">
      {apps &&
        apps.length > 0 &&
        apps.map((app, i: number) => (
          // <div >
          <div key={app.uuid} className="appComponent">
            <Link className="appUrl" to={`/app/${slugify(app.title)}/${app.uuid}`}>
              <img referrerPolicy="no-referrer" className="appImage" src={app.imageUrl} alt={app.title} />
            </Link>
            <div className="textBox">
              <Link className="appUrl" to={`/app/${slugify(app.title)}/${app.uuid}`}>
                <p className="title">{app.title}</p>
              </Link>
              {app.sellerName && <span className="statusBadge">By: {app.sellerName}</span>}
              <div className="categoriesPlatformsTags">
                <div className="pugBox">
                  {!!app?.platforms &&
                    Object.keys(app?.platforms)
                      .filter((p) => p !== 'requests' && !!app?.platforms[p as Platform]?.url)
                      .map((p) => (
                        <a
                          key={p}
                          className="linkBox platforms"
                          rel="noreferrer"
                          target="_blank"
                          href={app.platforms[p as Platform]?.url ?? '/'}
                        >
                          {platformOptionsKeys[p as Platform]}
                        </a>
                      ))}
                </div>
                <div className="pugBox">
                  {!!app?.categories?.length &&
                    app?.categories.map((c) => (
                      <Link key={c} className="linkBox" to={`/apps?category=${c}`}>
                        {c}
                      </Link>
                    ))}
                  {!!app?.tags?.length &&
                    app?.tags.map((t) => (
                      <Link key={t} className="linkBox tags" to={`/apps?tag=${t}`}>
                        {t}
                      </Link>
                    ))}
                </div>
              </div>
              <p
                onClick={() => handleExpandDescription(i)}
                className={classNames('description', {
                  expanded: expandDescription[i],
                })}
              >
                {app.description}
              </p>
            </div>
            <div className="appComponent_edit_menu">
              <FiMoreHorizontal
                onClick={() => setIsEditing(isEditing === i ? -1 : i)}
                className="edit_icon_in_my_list"
              />
              {deleteApp && isEditing === i && (
                <div className="appComponent_edit_menu_list">
                  <Button
                    onClick={() => {
                      setIsEditing(-1);
                      deleteApp(app);
                    }}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
          </div>
          // </div>
        ))}
    </div>
  );
}

export default ExtendedAppList;
