import { appInterface } from 'interfaces';

/* eslint-disable import/no-anonymous-default-export */
export function filterSelectorArray(selectorValues?: any[]) {
  const filterSelectorPusher: string[] = [];
  selectorValues?.map((selector) => filterSelectorPusher.push(selector.value));
  return filterSelectorPusher;
}

export function checkAddedApps(
  localApps: appInterface.IApp[] | appInterface.IExternalParsedApp[],
  searchApps: appInterface.IApp[] | appInterface.IExternalParsedApp[]
) {
  const filteredApps: appInterface.IApp[] | appInterface.IExternalParsedApp[] = searchApps;
  let addedAppIndex: number;
  localApps.map((localApp) => {
    addedAppIndex = searchApps.findIndex((app) => app.uuid === localApp.uuid);
    if (addedAppIndex >= 0) filteredApps[addedAppIndex].inList = true;
    return filteredApps;
  });
  return filteredApps;
}

export const checkAddedAppsByIds = (localApps: string[], externalApps: appInterface.IApp[]) => {
  return externalApps.filter((app) => !localApps.includes(app.uuid));
};
