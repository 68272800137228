import React from 'react';
import { BsXLg } from 'react-icons/bs';
import { FiEdit } from 'react-icons/fi';
import { MdOutlineDelete } from 'react-icons/md';
import { BiExpandAlt } from 'react-icons/bi';
import { Link } from 'react-router-dom';

import 'styles/components/generic/modal.scss';

function Modal({
  children,
  hideModal,
  editModal,
  deleteModal,
  owned,
  expandLink,
}: {
  children: JSX.Element | JSX.Element[];
  hideModal: Function;
  editModal?: Function;
  deleteModal?: Function;
  owned?: boolean;
  expandLink?: string;
}) {
  return (
    <div className="modal">
      <div className="innerModal">
        <div className="modelIconContainer">
          {expandLink && (
            <Link to={expandLink}>
              <BiExpandAlt className="expandIcon" />
            </Link>
          )}
          {editModal && owned && <FiEdit className="editIcon" onClick={() => editModal()} />}
          {deleteModal && owned && <MdOutlineDelete className="deleteIcon" onClick={() => deleteModal()} />}
          <BsXLg className="closeIcon" onClick={() => hideModal()} />
        </div>
        {children}
      </div>
    </div>
  );
}

export default Modal;
