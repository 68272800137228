import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import 'styles/pages/onboarding/credentials.scss';
import { InputField, PasswordInputField } from 'components/generic';
import { userController } from 'controllers';
import { userInterface } from 'interfaces';
import { errorHelper } from 'helpers';
import { Helmet } from 'react-helmet';

function LoginPage({ setIsLoggedIn }: { setIsLoggedIn: React.Dispatch<React.SetStateAction<any>> }) {
  const [loginData, setLoginData] = useState<userInterface.IUserLogin>({
    username: '',
    password: '',
  });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { state }: any = useLocation();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setLoginData({
      ...loginData,
      [e.target.name]: value,
    });
  }

  async function handleLogin() {
    try {
      if (loginData) {
        const response = await userController.login(loginData);
        if (response.waitlist) {
          enqueueSnackbar('You are on the waitlist for now, we will let you know once you will be able to login.', {
            variant: 'info',
          });
          return navigate('/');
        }
      } else throw new errorHelper.CodeError('Login data is missing', 401);
      enqueueSnackbar('Logged in', { variant: 'success' });
      setIsLoggedIn(true);
      state?.redirect ? navigate(-1) : navigate('/');
    } catch (error: any) {
      return enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  return (
    <div className="loginPage">
      <Helmet>
        <title>Login - Slocco</title>
      </Helmet>
      <h1 className="title">Login</h1>
      <div className="inputWrapper">
        <InputField
          name="username"
          label="Email/Username"
          value={loginData?.username}
          handleChange={(e) => handleChange(e)}
        />
        <PasswordInputField
          name="password"
          label="Password"
          value={loginData?.password}
          handleChange={(e) => handleChange(e)}
        />
      </div>
      <button type="button" onClick={handleLogin} className="btnLogin">
        Login
      </button>
      <p className="reset">
        <Link className="link" to="/reset">
          Forgot your password?
        </Link>
      </p>
      <p className="register">
        Do not have an account yet?
        <Link className="link" to="/register">
          Create an account
        </Link>
      </p>
    </div>
  );
}

export default LoginPage;
