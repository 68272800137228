import Add from './add.svg';
import Android from './android.svg';
import Ios from './ios.svg';
import Windows from './windows.svg';
import macOS from './macOS.svg';
import Linux from './linux.svg';
import Web from './web.svg';
import twitter from './twitter.svg';
import gear from './gear.svg'

export const Svg: any = {
  add: Add,
  gear,
  Android,
  iOS: Ios,
  Windows,
  macOS,
  Linux,
  Web,
  twitter,
};
