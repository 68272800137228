import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import 'styles/pages/allAppsPage.scss';
import { appController } from 'controllers';
import { appInterface, pageInterface } from 'interfaces';
import { AppList } from 'components/apps';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';

export default function AllAppsPage() {
  const [apps, setApps] = useState<appInterface.IApp[]>([]);
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const { enqueueSnackbar } = useSnackbar();
  const [params] = useSearchParams();
  const category = params.get('category');
  const tag = params.get('tag');

  async function getAllApps() {
    try {
      const appsFetch: pageInterface.IPage<appInterface.IApp> | undefined = category
        ? await appController.getManyAppsByCategories([category], page)
        : tag
        ? await appController.getManyAppsByTags([tag], page)
        : undefined;
      if (!appsFetch) return;
      setPage(page + 1);
      setApps(apps.concat(appsFetch.items));
      setCount(appsFetch.total);
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    return setIsLoading(false);
  }

  useEffect(() => {
    getAllApps();
  }, []);

  const title = category || tag || 'All Apps';

  return isLoading ? (
    <h1>Loading...</h1>
  ) : (
    <div className="allAppsPage">
      <Helmet>
        <title>{title} - Slocco</title>
      </Helmet>
      <h1 className="title">{title}</h1>
      <div className="appBox">
        <AppList title={title} description apps={apps} />
      </div>
      {apps.length < count && (
        <button type="button" onClick={() => getAllApps()} className="cta">
          Load More
        </button>
      )}
    </div>
  );
}
