import React from 'react';
import { Link } from 'react-router-dom';
import 'styles/pages/notFoundPage.scss';
import notFoundGraphic from 'assets/images/graphics/404.svg';
import { Helmet } from 'react-helmet';

function NotFoundPage() {
  return (
    <div className="notFound">
      <Helmet>
        <title>404 - Slocco</title>
      </Helmet>
      <img referrerPolicy="no-referrer" className="notFoundGraphic" src={notFoundGraphic} alt="404 Graphic" />
      <h2 className="notFoundLabel">
        Oops. You have hit a deadend.{' '}
        <Link className="link" to="/">
          Go home
        </Link>{' '}
        to explore apps and lists.
      </h2>
    </div>
  );
}

export default NotFoundPage;
