import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { userController } from 'controllers';
import 'styles/pages/profileSettingsPage.scss';
import { IUser, IUserUpdate } from 'interfaces/userInterface';
import { InputField } from 'components/generic';
import { Helmet } from 'react-helmet';

function ProfileSettingsPage({ userData }: { userData: IUser }) {
  const [userUpdateData, setUserUpdateData] = useState<IUserUpdate>();
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  async function updateUser(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (loading) return;
    try {
      setLoading(true);
      if (userUpdateData) await userController.updateUser({ userUpdateData });
      enqueueSnackbar('User data updated', { variant: 'success' });
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
    setLoading(false);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setUserUpdateData({
      ...userUpdateData,
      [e.target.name]: value,
    });
  }

  useEffect(() => {
    if (userData) {
      setUserUpdateData({
        name: userData.name,
      });
    }
  }, [userData]);

  return (
    <div className="profileSettingsPage">
      <Helmet>
        <title>Settings - Slocco</title>
      </Helmet>
      <h1>Profile Settings</h1>
      {userUpdateData && (
        <form className="inputWrapper" onSubmit={(e) => updateUser(e)}>
          <InputField
            name="name"
            required
            label="Name"
            value={userUpdateData.name}
            handleChange={(e) => handleChange(e)}
          />
          <button disabled={loading} type="submit" className="cta">
            Save
          </button>
        </form>
      )}
    </div>
  );
}

export default ProfileSettingsPage;
