/* eslint-disable max-len */
import React from 'react';

export function Add({ size = 20 }) {
  return (
    <svg width={size} height={size} viewBox="0 0 27 25" fill="white" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M13.1944 23.4375C10.1102 23.4375 7.21157 22.3 5.03119 20.2336C1.46704 16.8578 0.620949 11.7383 2.92503 7.49609C3.13367 7.1125 3.63011 6.96172 4.03584 7.15859C4.44074 7.35547 4.60072 7.82656 4.39209 8.21094C2.41704 11.8477 3.14191 16.2352 6.19725 19.1289C8.06591 20.9 10.5506 21.875 13.1944 21.875C15.8374 21.875 18.3229 20.9 20.1916 19.1289C22.0603 17.3578 23.0903 15.0039 23.0903 12.5C23.0903 9.99531 22.0611 7.64141 20.1916 5.87109C18.3221 4.10078 15.8383 3.125 13.1944 3.125C10.5506 3.125 8.06591 4.10078 6.19725 5.87109C5.87481 6.17656 5.35363 6.17656 5.03119 5.87109C4.70875 5.56562 4.70875 5.07187 5.03119 4.76641C7.21157 2.70078 10.1102 1.5625 13.1944 1.5625C16.2786 1.5625 19.1781 2.70078 21.3577 4.76641C23.5389 6.83203 24.7396 9.57812 24.7396 12.5C24.7396 15.4211 23.5389 18.168 21.3577 20.2336C19.1781 22.3 16.2786 23.4375 13.1944 23.4375Z"
          fill="#37C6A1"
        />
        <path
          d="M13.1944 17.1875C12.7392 17.1875 12.3698 16.8383 12.3698 16.4062V8.59375C12.3698 8.1625 12.7392 7.8125 13.1944 7.8125C13.6496 7.8125 14.0191 8.1625 14.0191 8.59375V16.4062C14.0191 16.8383 13.6496 17.1875 13.1944 17.1875Z"
          fill="#37C6A1"
        />
        <path
          d="M17.3177 13.2812H9.07117C8.61597 13.2812 8.24652 12.9312 8.24652 12.5C8.24652 12.0688 8.61597 11.7188 9.07117 11.7188H17.3177C17.7737 11.7188 18.1424 12.0688 18.1424 12.5C18.1424 12.9312 17.7737 13.2812 17.3177 13.2812Z"
          fill="#37C6A1"
        />
      </g>
      <defs>
        <clipPath id="clip0_123_1816">
          <rect width="26.3889" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
