import { IApp } from './appInterface';
import { LabelValue } from './generic';

export interface IUserLogin {
  username?: string;
  password?: string;
}

export enum EmailFrequency {
  Weekly = '1',
  BiWeekly = '2',
  Monthly = '3',
}

interface DateID {
  timestamp: number;
  id: string;
}

export interface SentTime {
  dayOfWeek: string;
  dayOfMonth: string;
}

export interface INewUser {
  name?: string;
  username?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  currency?: LabelValue;
}

export interface IUserUpdate {
  name?: string;
  config?: IUserConfig;
  currency?: LabelValue;
}

export interface SubscriptionsConfig {
  emailFrequency?: {
    id: string;
    name: string;
    sentTime: SentTime;
  };
  preferredCurrency?: {
    id: string;
    name: string;
  };
}

export interface IUserConfig {
  onboarding?: {
    emailVerified?: boolean;
    verificationCode?: string;
  };
  country?: {
    id: string;
    name: string;
  };
  waitlist?: {
    isOnWaitlist: boolean;
    converted?: Date;
  };
  passwordReset?: {
    token: string;
    verificationCode: string;
  };
  subscriptions?: SubscriptionsConfig;
}

export interface IUserPublic {
  uuid: string;
  name: string;
  username: string;
  isFollowed?: boolean;
}

export enum SubscriptionPlan {
  Free = 'Free',
  Paid = 'Paid',
}

export interface ISubscription {
  uuid: string;
  app: IApp;
  appId: string;
  timestamp: Date;
  status: 'Active' | 'Cancelled' | 'Expired';
  plan: SubscriptionPlan;
  cost: number;
  currency: string;
  interval: 'month' | 'year';
  note: string;
}

export interface ISubscriptionTotal {
  interval: string;
  cost: number;
  currency: string;
}

export interface ISubscriptionData {
  subscriptions: ISubscription[];
  totals: ISubscriptionTotal[];
  total: string;
}

export interface INewSubscription {
  appId: string;
  plan: SubscriptionPlan;
  cost?: number;
  currency: LabelValue;
  interval: 'month' | 'year';
  note: string;
}

export interface IUpdateSubscription extends Omit<ISubscription, 'currency'> {
  currency: LabelValue;
}

export interface INewSubscriptionSanitized {
  appId: string;
  plan: SubscriptionPlan;
  cost?: number;
  currency?: string;
  interval: 'month' | 'year';
  note: string;
}

export interface IUser {
  uuid: string;
  name: string;
  username: string;
  email: string;
  followedLists?: DateID[];
  followedUsers?: DateID[];
  lists: string[];
  password: string;
  refresh_token?: string;
  timestamp?: Date;
  lastLogin?: Date;
  config?: IUserConfig;
  subscriptions?: ISubscription[];
  currency?: LabelValue;
}

export interface IJwtDecode {
  email: string;
  exp: number;
  iat: number;
  name: string;
  uuid: string;
}
