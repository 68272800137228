import { userInterface } from 'interfaces';
import { errorHelper } from 'helpers';
import { getAuthInstance, getInstance } from 'utils/axiosInstance';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const instance = getInstance(`${backendURL}/users`);
const authInstance = getAuthInstance(`${backendURL}/users`);

export async function login(userLoginData: userInterface.IUserLogin) {
  try {
    const { data } = await instance.post('/login', JSON.stringify({ userLoginData }));
    return data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}

export async function getUser(): Promise<userInterface.IUser> {
  try {
    const response = await authInstance.get('');
    return response.data;
  } catch (error: any) {
    console.log({ error });
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}

export async function updateUser({ userUpdateData }: { userUpdateData: userInterface.IUserUpdate }) {
  try {
    await authInstance.put('/', JSON.stringify({ userUpdateData }));
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}

export async function requestPassReset({ email }: { email: string }) {
  try {
    await instance.post('/reset-password', JSON.stringify({ email }));
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}

export async function setNewPassword({ password, confirmPassword }: { password: string; confirmPassword: string }) {
  try {
    await instance.post('/reset-password/new-password', JSON.stringify({ password, confirmPassword }));
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}

export async function verifyEmail(verificationCode: string) {
  try {
    await authInstance.post('/verify-email', JSON.stringify({ verificationCode }));
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}

export async function verifyPassEmail({ verificationCode, email }: { verificationCode: string; email: string }) {
  try {
    await instance.post('/reset-password/verify', JSON.stringify({ verificationCode, email }));
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}

export async function resendCode() {
  try {
    await authInstance.post('/verify-email/resend');
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}

export async function register(userData: userInterface.INewUser) {
  try {
    await instance.post('/register', JSON.stringify({ userData }));
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}

export async function followList({ listId, unfollow }: { listId: string; unfollow?: boolean }) {
  try {
    await authInstance.put('/list/follow', JSON.stringify({ listId, unfollow }));
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}

export async function followUser({ followedUserId, unfollow }: { followedUserId: string; unfollow?: boolean }) {
  try {
    const response = await authInstance.put('/follow', JSON.stringify({ followedUserId, unfollow }));
    return response.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}

export async function logout() {
  try {
    await instance.post('/logout');
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}

export async function getProtectedUserById({ uuid }: { uuid: string }): Promise<userInterface.IUserPublic> {
  try {
    const response = await authInstance.get('/protected/id', {
      params: { uuid },
    });
    return response.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}

export async function getProtectedUsersByIds({
  uuidList,
}: {
  uuidList: string[];
}): Promise<userInterface.IUserPublic[]> {
  try {
    const response = await authInstance.get('/protected/id-list', {
      params: { uuidList },
    });
    return response.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}

export async function getPublicUserById({ userId }: { userId: string }): Promise<userInterface.IUserPublic> {
  try {
    const response = await instance.get('/public/id', {
      params: { userId },
    });
    return response.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}

export async function getUsersByName({ name }: { name: string }): Promise<userInterface.IUserPublic[]> {
  try {
    const response = await authInstance.get('/protected/name', {
      params: { name },
    });
    return response.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}
