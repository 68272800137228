import React from 'react';
import { BsPlusLg, BsCheckLg } from 'react-icons/bs';
import { appInterface } from 'interfaces';

function AppSearchSaveComponent({
  appData,
  saveApp,
  inList,
}: {
  appData: appInterface.IExternalParsedApp | appInterface.IApp;
  saveApp: Function;
  inList?: boolean;
}) {
  return (
    <div
      className="appComponent"
      role="button"
      onKeyDown={() => {
        if ((inList && !appData?.inList) || (!inList && !appData?.added)) saveApp(appData);
      }}
      tabIndex={0}
      onClick={() => {
        if ((inList && !appData?.inList) || (!inList && !appData?.added)) saveApp(appData);
      }}
      key={appData.uuid}
    >
      <div className="dataBox">
        <img className="appImage" height="35" referrerPolicy="no-referrer" src={appData.imageUrl} alt={appData.title} />
        <div className="textBox">
          <p className="title">{appData.title}</p>
        </div>
      </div>
      {(inList && appData.inList) || (!inList && !appData?.added) ? (
        <BsCheckLg className="saveIcon addedIcon" />
      ) : (
        <BsPlusLg className="saveIcon" />
      )}
    </div>
  );
}

export default AppSearchSaveComponent;
