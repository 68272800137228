import React, { useEffect, useState } from 'react';
import 'styles/components/list/listTable.scss';
import { listInterface, userInterface } from 'interfaces';
import ListPreview from 'components/list/listPreview';

interface ListTableProps {
  hideBadge?: boolean;
  vertical?: boolean;
  allowFollowing?: boolean;
  userData?: userInterface.IUser;
  lists?: listInterface.IList[];
  reloadUser?: Function;
  reloadLists: Function;
  openedListId?: string;
  newsFeed?: listInterface.INewsFeedList[];
}

function ListTable({
  hideBadge,
  vertical,
  allowFollowing,
  userData,
  lists,
  newsFeed,
  reloadUser,
  reloadLists,
  openedListId,
}: ListTableProps) {
  const [parsedLists, setParsedLists] = useState<listInterface.IList[] | listInterface.INewsFeedList[] | undefined>(
    newsFeed || lists
  );

  useEffect(() => {
    if (allowFollowing && userData?.followedLists && lists) {
      setParsedLists(
        lists?.map((list) =>
          userData.followedLists?.find((followedList) => followedList.id === list.uuid)
            ? { ...list, isFollowed: true }
            : list
        )
      );
    } else if (allowFollowing && userData?.followedLists && newsFeed) {
      setParsedLists(
        newsFeed?.map((list) =>
          userData.followedLists?.find((followedList) => followedList.id === list.uuid)
            ? { ...list, isFollowed: true }
            : list
        )
      );
    } else {
      setParsedLists(lists || newsFeed);
    }
  }, [allowFollowing, lists, userData]);

  return (
    <div className={`listTable ${vertical ? 'vertical' : null}`}>
      {!!parsedLists &&
        parsedLists?.length > 0 &&
        parsedLists.map((parsedList) => (
          <ListPreview
            listIsOpen={openedListId === parsedList.uuid}
            allowFollowing={allowFollowing}
            hideBadge={hideBadge}
            userData={userData}
            reloadUser={reloadUser}
            reloadLists={reloadLists}
            listData={parsedList}
            key={parsedList.uuid}
          />
        ))}
    </div>
  );
}

export default ListTable;
