/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import 'styles/components/users/userPreview.scss';
import { userInterface } from 'interfaces';

function ListPreview({ userData }: { userData: userInterface.IUserPublic }) {
  return (
    <div className="userPreview" key={userData.uuid}>
      <div className="userTextBox">
        <h1 className="title">{userData.name}</h1>
      </div>
    </div>
  );
}

export default ListPreview;
