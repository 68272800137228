import { appInterface, listInterface, pageInterface } from 'interfaces';
import { errorHelper } from 'helpers';
import { getAuthInstance, getInstance, getSoftAuthInstance } from 'utils/axiosInstance';

const backendURL = process.env.REACT_APP_BACKEND_URL;
const instance = getInstance(`${backendURL}/lists`);
const authInstance = getAuthInstance(`${backendURL}/lists`);
const softAuthInstance = getSoftAuthInstance(`${backendURL}/lists`);

export async function createList(listData: listInterface.INewList): Promise<listInterface.IList> {
  try {
    const newList = await authInstance.post('/', JSON.stringify({ listData }));
    return newList.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function deleteList({ uuid }: { uuid: string }) {
  try {
    await authInstance.delete('/', {
      data: JSON.stringify({ uuid }),
    });
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function updateList({ uuid, newListData }: { uuid: string; newListData: listInterface.INewList }) {
  try {
    await authInstance.put('/', JSON.stringify({ uuid, newListData }));
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function addAppToList({
  uuid,
  appData,
}: {
  uuid: string;
  appData: appInterface.IApp | appInterface.IExternalParsedApp;
}) {
  try {
    await authInstance.post('/app', JSON.stringify({ uuid, appData }));
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function deleteAppFromList(uuid: string, appId: string) {
  try {
    await authInstance.put('/app/delete', JSON.stringify({ uuid, appId }));
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function getListsByUserId(
  userId: string,
  page?: number
): Promise<pageInterface.IPage<listInterface.IList>> {
  try {
    const lists = await authInstance.get('/user/paginated', {
      params: { userId, page },
    });
    return lists.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function getPublicListsByUserId(
  userId: string,
  page?: number
): Promise<pageInterface.IPage<listInterface.IList>> {
  try {
    const lists = await instance.get('/public/user/paginated', {
      params: { userId, page },
    });
    return lists.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function getPublicLists({
  followed,
  name,
}: {
  followed?: boolean;
  name?: string;
}): Promise<listInterface.IList[]> {
  try {
    let lists;
    if (followed) lists = await authInstance.get('/public', { params: { followed, name } });
    else lists = await instance.get('/public', { params: { name } });
    return lists.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function getNewsFeed({
  page,
}: {
  page: number;
}): Promise<pageInterface.IPage<listInterface.INewsFeedList>> {
  try {
    const lists = await instance.get('/public/news-feed', { params: { page } });
    return lists.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function getPublicListsPaginated({
  followed,
  name,
  page,
}: {
  followed?: boolean;
  name?: string;
  page?: number;
}): Promise<pageInterface.IPage<listInterface.IList>> {
  try {
    let lists;
    if (followed)
      lists = await authInstance.get('/public/paginated', {
        params: { followed, name, page },
      });
    else
      lists = await instance.get('/public/paginated', {
        params: { name, page },
      });

    return lists.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function getListById(uuid: string) {
  try {
    const lists = await softAuthInstance.get('/', { params: { uuid } });
    return lists.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}

export async function getManyListsByName(name: string): Promise<listInterface.IList[]> {
  try {
    const lists = await instance.get('/public/search/name', {
      params: { name },
    });
    return lists.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500);
  }
}
