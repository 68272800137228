import React from 'react';
import 'styles/components/search/suggestions.scss';
import { appInterface } from 'interfaces';
import { AppSearchSaveComponent, NoSearchResults } from '.';

function SavableSuggestions({
  apps,
  saveApp,
  inList,
  addApp,
}: {
  apps?: appInterface.IExternalParsedApp[] | appInterface.IApp[];
  saveApp: Function;
  inList?: boolean;
  addApp?: boolean;
}) {
  return (
    <div className={`suggestions ${apps && 'active'}`}>
      {apps && apps.length > 0 ? (
        apps.map((app) => (
          <AppSearchSaveComponent
            key={app.uuid}
            inList={inList}
            appData={app}
            saveApp={(appId: appInterface.IExternalParsedApp | appInterface.IApp) => saveApp(appId)}
          />
        ))
      ) : (
        <NoSearchResults addApp={addApp} />
      )}
    </div>
  );
}

export default SavableSuggestions;
