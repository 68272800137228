import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { AiOutlineStar, AiTwotoneStar } from 'react-icons/ai';

import { ListTable } from 'components/list';
import { listController, userController } from 'controllers';
import { listInterface, userInterface } from 'interfaces';
import { pageSize } from 'utils/constants';
import 'styles/pages/UserPage.scss';
import { Helmet } from 'react-helmet';

function UserPage({ isLoggedIn }: { isLoggedIn?: boolean }) {
  const { uuid } = useParams();
  const [user, setUser] = useState<userInterface.IUserPublic>();
  const [lists, setLists] = useState<listInterface.IList[]>();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const canLoadMore = total > page * pageSize;

  async function getUserData() {
    try {
      if (uuid) {
        let fetchedUser;
        if (isLoggedIn) fetchedUser = await userController.getProtectedUserById({ uuid });
        else
          fetchedUser = await userController.getPublicUserById({
            userId: uuid,
          });
        const fetchedLists = await listController.getPublicListsByUserId(uuid);
        setUser(fetchedUser);
        setLists(fetchedLists.items);
        setTotal(fetchedLists.total);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function getListsPaginated() {
    try {
      if (uuid) {
        const listFetch = await listController.getPublicListsByUserId(uuid, page + 1);
        if (lists) setLists([...lists, ...listFetch.items]);
        setPage(page + 1);
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  async function followUser({ unfollow }: { unfollow?: boolean }) {
    try {
      if (uuid) setUser(await userController.followUser({ followedUserId: uuid, unfollow }));
      if (unfollow) enqueueSnackbar('User has been unfollowed', { variant: 'success' });
      else enqueueSnackbar('User has been followed', { variant: 'success' });
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    getUserData();
  }, [isLoggedIn]);

  return user && lists ? (
    <div className="userPage">
      <Helmet>
        <title>{user.name} - Slocco</title>
      </Helmet>
      <div className="nameWrapper">
        <h1>{user.name}</h1>
        {isLoggedIn &&
          (user.isFollowed ? (
            <AiTwotoneStar onClick={() => followUser({ unfollow: true })} className="followIcon" />
          ) : (
            <AiOutlineStar onClick={() => followUser({})} className="followIcon" />
          ))}
      </div>
      <p>Username: {user.username}</p>
      <ListTable reloadLists={() => lists} lists={lists} />
      {canLoadMore ? (
        <button type="button" className="cta" onClick={() => getListsPaginated()}>
          Load More
        </button>
      ) : null}
    </div>
  ) : (
    <h1>Loading...</h1>
  );
}

export default UserPage;
