import { errorHelper } from 'helpers';
import { getAuthInstance } from 'utils/axiosInstance';
import { INewSubscriptionSanitized, ISubscription, ISubscriptionData } from 'interfaces/userInterface';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const authInstance = getAuthInstance(`${backendURL}/subscriptions`);
const instance = getAuthInstance(`${backendURL}/suggested-subscriptions`);

export async function getSubscriptions(): Promise<ISubscriptionData> {
  try {
    const response = await authInstance.get('/');
    return response.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}

export async function getSuggestedSubscriptions(): Promise<ISubscriptionData> {
  try {
    const response = await instance.get('/');
    return response.data;
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}

export async function addSubscription(subscription: INewSubscriptionSanitized) {
  try {
    await authInstance.post('/', JSON.stringify({ subscription }));
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}

export async function editSubscription(subscription: ISubscription) {
  try {
    await authInstance.put(`/`, JSON.stringify({ subscription }));
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}

export async function deleteSubscription({ subscriptionId }: { subscriptionId: string }) {
  try {
    await authInstance.delete('/', {
      data: { subscriptionId },
    });
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response?.data, 500);
  }
}
